import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Home from "./Pages/Home";
import Hao from "./Pages/Hao";
import Jie from "./Pages/Jie";
import Feng from "./Pages/Feng";
import Mom from "./Pages/Mom";
import HomeBucks from "./Pages/HomeBucks";
import LearningLottery from "./Pages/LearningLottery";
import School from "./Pages/School";
import DailyPrompt from "./Pages/DailyPrompt";
import English from "./Pages/English";
import Math from "./Pages/Math";
import Chinese from "./Pages/Chinese";
import FamilyFun from "./Pages/FamilyFun";
import Error from "./Pages/Error";
import clock from "./Assets/Images/clock.png";
import codexmon from "./Assets/Images/codexmon.png";
import github from "./Assets/Images/github.png";
import linkedin from "./Assets/Images/linkedin.png";
import "./App.css";

function App() {
  const [display, setDisplay] = useState("");
  const month = new Date().getMonth();
  const year = new Date().getYear();
  const [day, setDay] = useState(0);
  const [hrMin, setHrMin] = useState(""); //must be hhMmAmPm 24-hour time
  const [isSummer, setIsSummer] = useState(false);

  // updates time every second
  const currentTime = () => {
    let today = new Date();
    // console.log(today);
    let hour = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    hour = hour < 10 ? `0${hour}` : hour;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    // console.log("currentTime start");
    setHrMin(`${hour}:${minutes}`);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12; // 12-hour time clock
    hour = hour ? hour : 12; // the hour '0' is '12'
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    let now = `${hour}:${minutes}:${seconds} ${ampm}`;
    // console.log("currentTime display");
    setDisplay(now);
    // let hhMm = `${hour}:${minutes} ${ampm}`;
    // setHrMin(hhMm);
    let dayOfWeek = today.getDay();
    // console.log("currentTime day");
    setDay(dayOfWeek);
    // console.log(dayOfWeek);
    //     check if the alarmList includes the current time , "now"
    //     if yes, ringing() is called
    // if(alarmList.includes(now) ){
    //     ringing(now);
    // }
    // return now;
  };

  // const handleRefresh = () => {
  //   window.location.reload();
  // }

  useEffect(() => {
    document.title = "At Home Camp Schedule";
    const interval = setInterval(() => {
      currentTime();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="App">
      {/* <header className="app-header"></header> */}
      <div className="navbar">
        <NavLink to="/" className="btn btn-nav">
          ⏰ Stopwatch
        </NavLink>
        {isSummer ? 
        <>
        <NavLink to="/feng" className="btn btn-nav">
          🦉 Feng Lan
        </NavLink>
        <NavLink to="/jie" className="btn btn-nav">
          🐢 Jie Yi
        </NavLink>
        <NavLink to="/hao" className="btn btn-nav">
          🐆 Hao Ran
        </NavLink>
        <NavLink to="/mom" className="btn btn-nav">
          🦁 Mom
        </NavLink>
        </> : <>
        <NavLink to="/prompt" className="btn btn-nav">
          ❓ Daily Prompt
        </NavLink>
        <NavLink to="/english" className="btn btn-nav">
          🔠 English
        </NavLink>
        <NavLink to="/math" className="btn btn-nav">
          📐 Math
        </NavLink>
        <NavLink to="/chinese" className="btn btn-nav">
          ㊥ Zhōngwén
        </NavLink>
        </>}
        {(month <= 8 || month >= 6) && year === 2022 && isSummer && <NavLink to="/lottery" className="btn btn-nav">
          🎟️ Learning Lottery
        </NavLink>}
        <NavLink to="/bucks" className="btn btn-nav">
          💵 Home Bucks
        </NavLink>
        <button className="summerToggle" onClick={() => setIsSummer(!isSummer)}>{isSummer ? <NavLink to="/school">Go To School Year</NavLink> : <NavLink exact="true" to="/">Go To Summer Break</NavLink>}</button>
      </div>
      <div className="current-time-section">
        <img src={clock} alt="clock" />
        <h2>{display}</h2>
      </div>
      <Routes>
        <Route
          exact="true"
          path="/"
          element={
            <Home
              title="Stopwatch"
              // hhMm={hrMin}
              now={display}
            />
          }
        />
        <Route
          title="Feng Time"
          path="feng"
          element={<Feng day={day} hhMm={hrMin} now={display} title="Feng Lan Time"/>}
        />
        <Route
          title="Jie Time"
          path="jie"
          element={
            <Jie
              day={day}
              hhMm={hrMin}
              now={display}
              title="Jie Yi Time"
              // now={now}
            />
          }
        />
        <Route
          title="Hao Time"
          path="hao"
          element={<Hao day={day} hhMm={hrMin} now={display} title="Hao Ran Time"/>}
        />
        <Route
          title="Mom Time"
          path="Mom"
          element={<Mom day={day} hhMm={hrMin} now={display} title="Mom's Schedule"/>}
        />
        <Route
          title="Learning Lottery"
          path="lottery"
          element={<LearningLottery title="Learning Lottery"/>}
        />
        <Route
          title="Home Bucks"
          path="bucks"
          element={<HomeBucks title="Home Bucks"/>}
        />
         <Route
          title="School Schedules"
          path="school"
          element={<School day={day} hhMm={hrMin} now={display} title="School Year Time"/>}
        />
        <Route
          title="Today's Prompt"
          path="prompt"
          element={<DailyPrompt day={day} hhMm={hrMin} now={display} title="Today's Prompt"/>}
        />
        <Route
          title="English"
          path="english"
          element={<English day={day} hhMm={hrMin} now={display} title="English"/>}
        />
        <Route
          title="Math"
          path="math"
          element={<Math day={day} hhMm={hrMin} now={display} title="Math"/>}
        />
         <Route
          title="Chinese"
          path="chinese"
          element={<Chinese day={day} hhMm={hrMin} now={display} title="Chinese"/>}
        />
         <Route
          title="Family Fun"
          path="familyfun"
          element={<FamilyFun day={day} hhMm={hrMin} now={display} title="Family Fun"/>}
        />
        <Route title="Page Not Found" path="*" element={<Error title="Page not found"/>} />
      </Routes>

      {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      <footer className="app-footer">
        <p className="footer-copyright">
          &#169; 2022 Gloria Ng. All rights reserved.
        </p>
        <div>
        <a href="https://codexmon.github.io/">
          <img className="codexmon" src={codexmon} alt="Codexmon" />
        </a>
        <a href="https://github.com/codexmon">
          <img src={github} alt="Github" />
        </a>
        <a href="https://linkedin.com/in/fengshuigal">
          <img src={linkedin} alt="LinkedIn" />
        </a>
      </div>
      </footer>
    </div>
  );
}

export default App;
