import React, {useState, useEffect, useCallback} from "react";
import raffle from "../Assets/Images/prize-draw.jpeg";
import dice1 from "../Assets/Images/dice1.jpg";
import dice2 from "../Assets/Images/dice2.jpg";
import dice3 from "../Assets/Images/dice3.jpg";
import dice4 from "../Assets/Images/dice4.jpg";
import dice5 from "../Assets/Images/dice5.jpg";
import dice6 from "../Assets/Images/dice6.jpg";
import marbleJar from "../Assets/Images/marblejar.png";
import { prizes } from "../Data/PrizesData";

const LearningLottery = (props) => {
    // console.log(props);
    const [day, setDay] = useState(0);
    const [form, setForm] = useState({date: new Date(), tickets: 0, who: "", pwdApproved: ""});
    const [totals,setTotals] = useState({marbles: 0, hao: 0, jie: 0, feng: 0}); //totals at start of fri, 7/29/22
    // const [marbles,setMarbles] = useState(0);
    const [marbleFill, setMarbleFill] = useState({background: ""});
    const [mainDraw, setMainDraw] = useState([]);
    // const [winner, setWinner] = useState("");
    const [winnerOrder, setWinnerOrder] = useState([]);
    const [shuffleClick, setShuffleClick] = useState(0);
    const [shuffle, setShuffle] = useState(prizes);
    const [diceRolls, setDiceRolls] = useState([]);
    const diceAll = [dice1, dice2, dice3, dice4, dice5, dice6];
    
    // Config variables
    // const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
    // const SHEET_ID = process.env.REACT_APP_SHEET_ID;
    // const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
    // const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;
    // const spreadsheetId = process.env.REACT_APP_SPREADSHEET_ID || "1_PHTGlbJMUTtJB32hG8_rtRVHMWde6Z5gsDNAftVnJs";
    // const SHEET_ID = process.env.REACT_APP_SHEET_ID || "1694392374";
    // const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL || "demo-168@ticket-tracker-3000.iam.gserviceaccount.com";
    // const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY || "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCL/975LrfN0so3\nWjsS2GykFfgXCn0YBjo292lWu5v3mzfC44Czl89Qn+XTsSrxM5MSuV/U47qufFLR\nblPoLdF06wCmsHamHMPioV0oJKjjy/2X8Dr75gVT7fINlbMz3t88dBicAt+bISn7\nQPDmrn6zra8XldfxndUweDl+/XPRfb5u5rm6V+MV+B1wWxcyB7QqpHnqRqlUfEEj\n7uHp4eofIYQzjf5zMGL5jsd8ai9YuWw/wqVSuIA1k4Y+JoNRbKNt/gaycbKkdDWO\n3PLp82Pe2MBiBu5fgUsI+iBkvTQTdNHfXo72lvLyrY5ihp6t5uAhm/fGti81XdAN\nYXWXx4s9AgMBAAECggEABTpzicvIWnvuSF3qydviRsh5QOcH3+9PmkDoIrCT9Q3L\nadBUPKDFQcYO77Z0m5ho/DCHFyzACocjEqYzpP6dDWg/WHpLnA2D9UFAeODRm9xA\n5r02frTK6yT+PqqiDCRdIuVVC/KBhkoZRsRYmszWd4Bbgx5595CE5y/+ObNecWra\nHX2oRxRuZFJECTX8mwv+qfmAqPaJ1x15smzarVRgokZPMnKKF+8vdFRDC84XHRUU\nZb7IfeSc/Kdak3kI9dtIgjAyVRs2FU94u0hsjwCxUC0v5cko85sVMKftBC9WSWv2\nldw7anpxfKwuAfZ6uogrsOp7ysWr0NN4y396kGpnAQKBgQDDj7b4hpX5D8Ew1Dbx\nGzXgEYJCOGZVPsdIR7oSGp52XvuuQ5Kfm4bTwG3CGyyJxCGJsxBL0MJ0492KORn5\ntiQWjJSBjfLkVQsTjUsHfXRht0tIQ8N08dg59IO/fh6XZPIQIt908dW8JhAPeq0M\nxeKBoQ+BRs3OvOKQKn55tsYrgQKBgQC3REAl9MS9wOchzLlSl2ka9yy3JjgbAuYZ\njpcfLd2G0tL/ijyvzYojzQ7i9ekWycKqxAJku71djnIhK2ydzheCNvNgSvt3Ew2A\ne4NltG6oh2pRKKo3MOLgrddSTE2AGYoGhujsp2rExuWzZfE9tHYll7LT+3CoK/3F\n+3AuxrPtvQKBgE0b8Khj4OSYurM3Kga9lOup27Oiq273VvqAFbUnu82uvAxFZp36\nZBpAaMa9kj0sEZFLAqTyRopygpsK0twsXmRpv/CpeNlhUwG9l5T3eZlB/bQbzAjj\nhz+q0vwBjaxiSDPmnDdvV7hdRb3kwDEmIZ69U/DEa0gWIDt6Fe6GC/OBAoGAMARG\n1msKPTlUlauBBt264SDP63CtFI6u4RVWDQpEV3XXaLK8ccdloTmx489QcfMM+RcA\nFYqG9ANFbeFlxPbhe/GP1LPwrOMmD3rNE5GxyKAqP/dqmalKWFnT57EsQRrv6gPg\n9VhsBgCi4F0eVu94YSMc7KOdgcJ87CmT2/2d2PUCgYEAj3QpjAH7iGnpJo5tOLfz\nGRrgO3wACUFKG+Ks2lOucV5xDjW5i2ybqri4+wmcDqk+d0DFOsm3dSoylXlbtgF1\n45bgTHSvataxWih1l5w37qt3oGraYqC8tMCyFHvD/rc5CdS2m2v8zsj9cxEGUyA9\nhbQWdCYvxQwTqJYMnttNQiA=\n-----END PRIVATE KEY-----\n";
    // const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
    // console.log(doc);

    // const appendSpreadsheet = async (row) => {
    //     try {
    //         await doc.useServiceAccountAuth({
    //         client_email: CLIENT_EMAIL,
    //         private_key: PRIVATE_KEY,
    //         });
    //         // loads document properties and worksheets
    //         await doc.loadInfo();

    //         const sheet = doc.sheetsById[SHEET_ID];
    //         const result = await sheet.addRow(row);
    //         console.log(result);
    //     } catch (e) {
    //         console.error('Error: ', e);
    //     }
    // };

    // const handleSubmit = (e) => {
    //     const newRow = { ...form };
    //     // appendSpreadsheet(newRow);
    // } 

    const handleSelect = (e) => {
        e.target.select();
    }

    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setForm({...form, [e.target.name]: value});
        // console.log(form);
    }

    // using sheet.best - this works for appending another row; only limited to 100 API calls on freemium account, though.
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(form);
        // const user = process.env.REACT_APP_USER;
        // const sheetsId = process.env.REACT_APP_SHEETS_ID;
        // const apiKey = process.env.REACT_APP_API_KEY;
        // const url = `https://v1.nocodeapi.com/${user}/google_sheets/${sheetsId}/addRows?tabId=LearningLotteryForm&api_key=${apiKey}`;
        try {
            // old deleted connection https://sheet.best/api/sheets/f5bc3521-6b2a-430e-8709-d8c72fd1a652
            // another deleted connection https://sheet.best/api/sheets/c342a2e3-deb9-4489-8d86-3ed5a63f8f70
            // third active connection https://sheet.best/api/sheets/e1bbc476-8273-4314-90ff-d66ea80a1c79

            // "https://v1.nocodeapi.com/terra3000/google_sheets/DoxQlbCyTXAarMFc/addRows?tabId=LearningLotteryForm&api_key=YpTrqFJglWcJNNxNX"
            // const response = await             
            fetch("https://v1.nocodeapi.com/terra3000/google_sheets/DoxQlbCyTXAarMFc/addRows?tabId=LearningLotteryForm&api_key=YpTrqFJglWcJNNxNX", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify([form])});
            // console.log(response);
            // const data = await response.json();
            // console.log(data);
        } catch(err) {
            console.log(err.message);
        } finally {
            setForm({date: new Date()});
        }
    }

    const getDay = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        // console.log(dayOfWeek);
        setDay(dayOfWeek);
        // setDay(5);
    }

    const getData = useCallback(
        async () => {
        //sheet.best counts every record found as a request!
        // const user = process.env.REACT_APP_USER;
        // const sheetsId = process.env.REACT_APP_SHEETS_ID;
        // const apiKey = process.env.REACT_APP_API_KEY;
        // const getUrl = process.env.GET_URL; 
        // `https://v1.nocodeapi.com/${user}/google_sheets/${sheetsId}?tabId=LearningLotteryForm&row_id=2&api_key=${apiKey}`;
        // "https://v1.nocodeapi.com/terra3000/google_sheets/DoxQlbCyTXAarMFc?tabId=LearningLotteryForm&row_id=2&api_key=YpTrqFJglWcJNNxNX"
        const response = await fetch("https://v1.nocodeapi.com/terra3000/google_sheets/DoxQlbCyTXAarMFc?tabId=LearningLotteryForm&row_id=2&api_key=YpTrqFJglWcJNNxNX");
        const data = await response.json();
        console.log(data);
        //sheet.best for row 1 of data
        // let value = data[0];
        let value = data;
        console.log(value);
        setTotals({...totals, marbles: value.marbles.toString(), hao: value.hao.toString(), jie: value.jie.toString(), feng: value.feng.toString()})
        // setMarbles(+value.marbles);
    },[totals]);

    const detectBrowser = () => {
        let sBrowser, sUsrAg = navigator.userAgent;

        // The order matters here, and this may report false positives for unlisted browsers.

        if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
        sBrowser = "unknown";
        }
        // console.log("You are using: " + sBrowser);
        return sBrowser;
    }
    const fillJar = (e) => {
        const newMarbles = totals.marbles;
        // console.log(newMarbles);
        // setMarbles(newMarbles);
        const fill = (100/100 * newMarbles/160).toFixed(2)*100;
        // const nonfill = (100/100 * (160 - newMarbles)/160).toFixed(2)*100;
        // console.log(fill, nonfill);
        // detect browser, then ternary statement below.
        const browser = detectBrowser();
        const newStyle = browser === "Mozilla Firefox" ? {
            background: `-moz-linear-gradient(bottom, blue ${fill}%), transparent 0%)`} : browser === "Google Chrome or Chromium" ? {background: `-webkit-linear-gradient(bottom, blue ${fill}%, transparent 0%)`} : browser === "Opera" ?
             {background: `-o-linear-gradient(bottom, blue ${fill}%, transparent 0%)`} : 
            {background: `linear-gradient(bottom, blue ${fill}%, transparent 0%)`};
        // console.log(newStyle);
        setMarbleFill(newStyle);
    }

    const makeTickets = (nums, ppl, index) => {
        const newArr = []
        for (var j = 0; j < nums.length; j++) {
            for (var i = 0; i < nums[index]; i++) {
            newArr.push(ppl[index]);
            }
            index++;
        }
        // console.log(newArr);
        setMainDraw(newArr);
        // const rafflePot = mainDraw.concat(newArr);
        // console.log(rafflePot);
        // setMainDraw(rafflePot);
        // console.log(mainDraw); // empty array
        // const result = Math.floor(Math.random() * newArr.length);
        // setWinner(newArr[result]);
        return newArr;
    }

    const drawTicket = (arr) => {
        const result = Math.floor(Math.random() * arr.length);
        // console.log(result, arr[result]);
        // setWinner(arr[result]);
        return arr[result];
    }

    // const getSnackBucks = useCallback(
    //     async () => {
    //         // https://v1.nocodeapi.com/terra3000/google_sheets/LbGgCsNkdVwACCCN?tabId=SnackBuckRewardTracker&row_id=2&api_key=YpTrqFJglWcJNNxNX
    // },[]);

    const handleRaffle = () => {
        const ppl = ["hao", "jie", "feng"];
        const nums = [+totals.hao, +totals.jie, +totals.feng];
        const rafflePot = makeTickets(nums, ppl, 0);
        // console.log(rafflePot);
        // setMainDraw(rafflePot);
        // console.log(mainDraw); //empty array
        const winner1 = drawTicket(rafflePot);
        // console.log(winner1);
        // setWinner(winner1);
        setWinnerOrder([winner1]);
        // const rafflePotFinal = rafflePot.filter(item => item != winner1);
        // const winner2 = drawTicket(rafflePotFinal);
        // console.log(winner2);
        // const newDraw = [...mainDraw];
        // console.log(newDraw);
        // console.log(arr); // undefined
        // const winner = drawTicket(mainDraw);
        // console.log(winner); //undefined
    }

    const getNextWinner = () => {
        if (winnerOrder.length < 2) {
            // console.log(mainDraw);
            const rafflePot2 = mainDraw.filter(winner1 => winner1 !== winnerOrder[0]);
            // console.log(rafflePot2);
            const winner2 = drawTicket(rafflePot2);
            // console.log(winner2);
            const winnersArr = [...winnerOrder];
            winnersArr.push(winner2);
            // console.log(winnersArr);
            setWinnerOrder(winnersArr);
        } else if (winnerOrder.length === 2) {
            // both winner3 methods below work.
            // const winner3 = mainDraw.filter(item => !winnerOrder.find(element => element === item));
            const winner3 = mainDraw.filter(item => !winnerOrder.includes(item));
            // console.log(winner3);
            const newWinnerOrder = [...winnerOrder]
            newWinnerOrder.push(winner3[0]);
            // console.log(newWinnerOrder);
            setWinnerOrder(newWinnerOrder);
        } 
        // else {
        //     return;
        // }
    }

    const shufflePrizes = (arr, num) => {
        const shuffled = [...arr].sort(() => 0.5 - Math.random());
        setShuffleClick(1);
        return shuffled.slice(0, num);
    }

    const handlePrizes = () => {
        const newPrizes = shufflePrizes(prizes, 6);
        setShuffle(newPrizes);
    }

    const handleDiceRoll = () => {
        const randomNum = Math.floor(Math.random() * 6) + 1;
        // console.log(randomNum);
        const dots = randomNum;
        const dice = diceAll[dots-1]; //diceAll is index 0
        // setRoll(dice);
        const diceObj = {id: dots, src: dice};
        // console.log(diceObj);
        const diceRollArr = [...diceRolls];
        diceRollArr.push(diceObj);
        // console.log(diceRollArr);
        setDiceRolls(diceRollArr);
        // console.log(shuffle);
    }

    const handleExit = () => {
        // setDay(0);
        // setWinner("");
        setShuffleClick(0);
        setWinnerOrder([]);
        setDiceRolls([]);
    }

    useEffect(()=> {
        document.title = `${props.title}`;
        getDay();
        getData(); //comment this back in later
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <div className="learning-lottery-section">
            <h2>Learning Lottery Tickets</h2>
            <div autoComplete="off" className="form-group" name="ticket-input-form"
            // onSubmit={handleSubmit}
            >
                <div className="form-control">
                    <label htmlFor="date">Date</label>
                    <input name="date" disabled value={form.date} onChange={handleChange} />
                </div>
                <div className="form-control">
                    <label htmlFor="tickets">Today's Tickets</label>
                    <input name="tickets" type="number" value={form.tickets} placeholder="Enter number" onChange={handleChange} onClick={handleSelect}/>
                </div>
                <div className="form-control">
                    <label htmlFor="who">For Whom?</label>
                    <div className="who-options">
                        <div>
                    <input name="who" type="radio" checked={form.who === "hao"} value="hao" onChange={handleChange}></input>
                    <label htmlFor="hao">Hao Ran</label>
                    </div>
                    <div>
                    <input name="who" type="radio" checked={form.who === "jie"} value="jie" onChange={handleChange}></input>
                    <label htmlFor="jie">Jie Yi</label>
                    </div>
                    <div>
                    <input name="who" type="radio" checked={form.who === "feng"} value="feng" onChange={handleChange}></input>
                    <label htmlFor="feng">Feng Lan</label>
                    </div>
                    </div>
                </div>
                <div className="form-control">
                    <label htmlFor="pwdApproved">Today's Password</label>
                    <input name="pwdApproved"
                    className="password" 
                    // type="password" 
                    autoComplete="off" value={form.pwdApproved} placeholder="Ask Mom to enter" onChange={handleChange} onClick={handleSelect}></input>
                </div>
                {form.pwdApproved && form.pwdApproved.split("").shift() !== "=" && +form.pwdApproved.split("").pop() === 8 && form.pwdApproved.length > 8 ?
                 <input className="btn form-submit" type="submit" name="submit" value="Submit" onClick={handleSubmit}/> :
                <input className="btn form-submit disabled" type="submit" name="submit-disabled" value="Submit" disabled/>
                }
            </div>
            <h2>Total Tickets</h2>
            <div className="ticket-display">
                <div className="ticket-holder">
                    <h3>Feng Lan</h3>
                    <p className="ticket-totals">{+totals.feng}</p>
                </div>
                <div className="ticket-holder">
                    <h3>Jie Yi</h3>
                    <p className="ticket-totals">{+totals.jie}</p>
                </div>
                 <div className="ticket-holder">
                    <h3>Hao Ran</h3>
                    <p className="ticket-totals">{+totals.hao}</p>
                </div>
            </div>
            <div>
                <h2>Marble Jar</h2>
            <p>Each ticket is a marble in the marble jar!</p>
            <img src={marbleJar} style={marbleFill} alt="marble jar" />
            </div>
            <button className="btn" onClick={fillJar}>View Marbles</button>
            {marbleFill.background && <p>The jar has {totals.marbles} total marbles. Get your next Marble Jar Reward when all 120 marbles are colored in!</p>}
            <hr/>
            <div className="raffle-section">
            {day > 4 ? 
                <div>
                    <h2>Weekly Raffle Time!</h2>
                    <p>Click the image below to see who rolls first!</p>
                    <img src={raffle} alt="raffle" onClick={handleRaffle}/>
                </div> :
                <div>
                    <h2>Get Ready for the Weekly Raffle!</h2>
                    <p>Earn tickets everyday by completing learning activities!</p>
                
                </div>
                }
                <div className="ticket-display">
                    {winnerOrder.length > 0 ? winnerOrder.map((winner, index) => 
                    <div className="ticket-holder" onClick={getNextWinner} key={index}>
                        <h3>{winner === "feng" ? "Feng Lan" : winner === "jie" ? "Jie Yi" : "Hao Ran"}</h3>
                        <p className="ticket-totals"></p>
                    </div>
                        ) : null}
                    {/*{winner === "feng" ? 
                    // <div className="ticket-holder" onClick={getNextWinner}>
                    //     <h3>Feng Lan</h3>
                    //     <p className="ticket-totals"></p>
                    // </div> : winner === "jie" ? 
                    // <div className="ticket-holder" onClick={getNextWinner}>
                    //     <h3>Jie Yi</h3>
                    //     <p className="ticket-totals"></p>
                    // </div> : winner === "hao" ? 
                    // <div className="ticket-holder" onClick={getNextWinner}>
                    //     <h3>Hao Ran</h3>
                    //     <p className="ticket-totals"></p>
                    // </div> :
                    // null}*/}
                </div>
                {/* {winner !== "" && */}
                {winnerOrder.length > 0 && winnerOrder.length < 3 && <p>Click the above ticket for the next winner(s)!</p>}
                {shuffleClick !== 1 && winnerOrder.length === 3 ? 
                <button className="btn" onClick={handlePrizes}>Shuffle Prizes</button>:null}
                    
                <div className="prizes-section">
                {shuffleClick > 0 && <div>
                    <h2>Prizes</h2>
                    <div  className="dice-prize-wrapper">
                {diceAll.map((face, index) => 
                    <div className="dice-prize" key={index}>
                        <img src={face} alt="dice face"/>
                        <p>{shuffle[index].prize}</p>
                    </div>)}
                    </div>
                    {diceRolls.length < 3 ? <button className="btn" onClick={handleDiceRoll}>Roll Dice</button> : <button className="btn disabled" disabled onClick={handleDiceRoll}>Roll Dice</button>}
                </div>
                }
               </div>
                {diceRolls.length > 0 && diceRolls.length <= 3 ? 
                <div className="dice-roll-section">
                    <div className="ticket-display">
                    {winnerOrder.length > 0 && winnerOrder.map((winner, index) => 
                    <div className="ticket-holder" onClick={getNextWinner} key={index}>
                        <h3>{winner === "feng" ? "Feng Lan" : winner === "jie" ? "Jie Yi" : "Hao Ran"}</h3>
                        <p className="ticket-totals"></p>
                    </div>
                        )}
                    </div>
                    <div className="dice-roll">
                        {diceRolls.map((result, index) => <div key={index}>
                            <img src={result.src} alt="dice roll"/>
                            <p>{shuffle[result.id-1].prize}</p>
                        </div>)}
                    </div>
                </div>
                : null}
                {diceRolls.length === 3 && <button className="btn" onClick={handleExit}>Exit Lottery</button>}
            </div>
        </div>
    );
}

export default LearningLottery;