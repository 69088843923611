import React, { useEffect, useState, useRef} from "react";
import { kidsYMFwork, momYMFwork, fengYMFholiday, jieYMFholiday, haoYMFholiday, momYMFholiday, fengYW, jieYW, haoYW, momYW, girlsYRam, haoYRam, momYRam, fengYR, jieYR, haoYR, momYR, girlsYFam, haoYFam, momYFam, fengYF, jieYF, haoYF, momYF, fengYSatVacay, jieYSatVacay, haoYSatVacay, momYSatVacay, fengYSun, jieYSun, haoYSun, momYSun } from "../Data/SchoolYearData";
import ListItem from "../Components/ListItem";
import bell2 from "../Assets/Audio/applause.mp3";
import bell3 from "../Assets/Audio/flute.mp3";
import bell4 from "../Assets/Audio/synth1.mp3";
import bell5 from "../Assets/Audio/seabirds.mp3";
import chevron from "../Assets/Images/chevron.png";

const School2 = (props) => {
 // console.log(props); //day of week, hh:mm as string
  const [holiday, setHoliday] = useState(false);
  const [showMinimum, setShowMinimum] = useState(null);
  const [minimumDay, setMinimumDay] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState("");
  const [isDesktop, setIsDesktop] = useState(false);
  const [alarmsFeng, setAlarmsFeng] = useState([]);
  const [alarmsJie, setAlarmsJie] = useState([]);
  const [alarmsHao, setAlarmsHao] = useState([]);
  const [alarmsMom, setAlarmsMom] = useState([]);
   const [alarmsPrevFeng, setAlarmsPrevFeng] = useState([]);
  const [alarmsPrevJie, setAlarmsPrevJie] = useState([]);
  const [alarmsPrevHao, setAlarmsPrevHao] = useState([]);
  const [alarmsPrevMom, setAlarmsPrevMom] = useState([]);
  const [alarmsNextFeng, setAlarmsNextFeng] = useState([]);
  const [alarmsNextJie, setAlarmsNextJie] = useState([]);
  const [alarmsNextHao, setAlarmsNextHao] = useState([]);
  const [alarmsNextMom, setAlarmsNextMom] = useState([]);
  const alarmBell2 = useRef(new Audio(bell2));
  const alarmBell3 = useRef(new Audio(bell3));
  const alarmBell4 = useRef(new Audio(bell4));
  const alarmBell5 = useRef(new Audio(bell5));
  const data = [{name: "Feng Lan", bell: bell2, all: alarmsFeng, prev: alarmsPrevFeng, next: alarmsNextFeng, ref: alarmBell2}, {name: "Jie Yi", bell: bell3, all: alarmsJie, prev: alarmsPrevJie, next: alarmsNextJie, ref: alarmBell3}, {name: "Hao Ran", bell: bell4, all: alarmsHao, prev: alarmsPrevHao, next: alarmsNextHao, ref: alarmBell4},{name: "Mom",bell: bell5, all: alarmsMom, prev: alarmsPrevMom, next: alarmsNextMom, ref: alarmBell5}];

  let newRefs = [];
  const newRef = useRef(isActive);

  // convert 12-hour to 24-hour time string cycle sans am/pm
  const checkTime = (timeStr) => {
    let hours = Number(timeStr.match(/^(\d+)/)[1]);
    let minutes = Number(timeStr.match(/:(\d+)/)[1]);
    let AMPM = timeStr.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    return hours + ":" + minutes;
  };

  const getList = (day) => {
    // console.log(day);
    let listFeng = [];
    let listJie = [];
    let listHao = [];
    let listMom = [];
    if ((holiday === true && day === 1) || (holiday === true && day === 5)) {
      listFeng = fengYMFholiday;
      listJie = jieYMFholiday;
      listHao = haoYMFholiday;
      listMom = momYMFholiday; 
    } else if ((holiday === true && day !== 1) || (holiday === true && day !== 5) || day === 6) {
      listFeng = fengYSatVacay;
      listJie = jieYSatVacay;
      listHao = haoYSatVacay;
      listMom = momYSatVacay; 
    } else if (minimumDay === true || day === 5) {
      listFeng = girlsYFam.concat(fengYF);
      listJie = girlsYFam.concat(jieYF);
      listHao = haoYFam.concat(haoYF);
      listMom = momYFam.concat(momYF);
    } else if (day === 3) {
      listFeng = fengYW;
      listJie = jieYW;
      listHao = haoYW;
      listMom = momYW;
    } else if (day === 4) {
      listFeng = girlsYRam.concat(fengYR);
      listJie = girlsYRam.concat(jieYR);
      listHao = haoYRam.concat(haoYR);
      listMom = momYRam.concat(momYR);
    } else if (day === 7) {
      listFeng = fengYSun;
      listJie = jieYSun;
      listHao = haoYSun;
      listMom = momYSun;
    } else {
      listFeng = kidsYMFwork;
      listJie = kidsYMFwork;
      listHao = kidsYMFwork;
      listMom = momYMFwork; 
    }
    setAlarmsFeng(listFeng);
    setAlarmsJie(listJie);
    setAlarmsHao(listHao);
    setAlarmsMom(listMom);
    // console.log(alarmsFeng, alarmsJie, alarmsHao, alarmsMom);
  };
const checkShift = (list, timeStr) => {
    // console.log("state", list);
    // let alarmListCopy = [...list];
    // console.log("copy", alarmListCopy);
    let timeOnlyArr = list.map((item) => checkTime(item.time)); //item sent in as string.
    // console.log(timeOnlyArr); //items are 24hr strings with only the time sans am/pm
    let prevArr = timeOnlyArr.filter((item) => item < timeStr);
    let start = list.slice(0, prevArr.length);
    let end = list.slice(prevArr.length);
    // console.log(start, end);
    let startReverse = start.reverse();
    // console.log(startReverse, end);
    // setPrevAlarms(startReverse);
    if (list === alarmsFeng) {
      setAlarmsPrevFeng(startReverse);
      setAlarmsNextFeng(end);
      // console.log(alarmsPrevFeng);
    } else if (list === alarmsJie) {
      setAlarmsPrevJie(startReverse);
      setAlarmsNextJie(end);
      // console.log(alarmsNextJie);
    } else if (list === alarmsHao) {
      setAlarmsPrevHao(startReverse);
      setAlarmsNextHao(end);
      // console.log(alarmsNextHao);
    } else {
      setAlarmsPrevMom(startReverse);
      setAlarmsNextMom(end);
      // console.log(alarmsNextMom);
    }
    // setNextAlarms(end);
    let currentTime = timeOnlyArr.filter((item) => item === timeStr);
    // console.log(currentTime);
    // setCurrentAlarm(currentTime);
    if (currentTime.length > 0) {
      if (list === alarmsFeng) {
        alarmBell2.current.play();
      } else if (list === alarmsJie) {
        alarmBell3.current.play();
      } else if (list === alarmsHao) {
        alarmBell4.current.play();
      } else {
        alarmBell5.current.play();
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    document.title = `${props.title} ${props.now}`;
  //     // console.log(props.hhMm);
      getList(props.day);
      const interval1 = setInterval(checkShift(alarmsFeng,props.hhMm), 1000);
      const interval2 = setInterval(checkShift(alarmsJie,props.hhMm), 1000);
      const interval3 = setInterval(checkShift(alarmsHao,props.hhMm), 1000);
      const interval8 = setInterval(checkShift(alarmsMom,props.hhMm), 1000);
      return () => {
        clearInterval(interval1);
        clearInterval(interval2);
        clearInterval(interval3);
        clearInterval(interval8);
      };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.now]);

  const handleHoliday = (e) => {
    const value = e.target.value;
    if (value === "Yes") {
        setHoliday(true);
    } else {
        setHoliday(false);
        setShowMinimum(true);
    }
  }

  const handleMinimum = (e) => {
    const value = e.target.value;
    if (value === "Yes") {
        setMinimumDay(true);
    } else {
        setMinimumDay(false);
    }
  }

  const handleDisplay = (e) => {
    const value = e.target.value;
    if (value === "Yes") {
        setIsActive(true);
        setIsDesktop(true);
    } else {
        setIsActive(false);
        setIsDesktop(false);
    }
  }

  const handleToggle = (e, index) => {
    isActive ? setActiveIndex("") :
    setActiveIndex(index);
  }

  return (
    <div className="school-alarms-section">
        <div className="holiday">
            <p>Is today a School Holiday?</p>
            <input type="radio" name="holiday" value="Yes" onChange={handleHoliday}/>
            <label htmlFor="holiday">Yes</label>
            <input type="radio" name="holiday" value="No" onChange={handleHoliday}/>
            <label htmlFor="holiday">No</label>
        </div>
        {showMinimum ? <div className="minimum-day">
            <p>Is today a minimum day?</p>
            <input type="radio" name="minimum" value="Yes" onChange={handleMinimum}/>
            <label htmlFor="minimum">Yes</label>
            <input type="radio" name="minimum" value="No" onChange={handleMinimum}/>
            <label htmlFor="minimum">No</label>
        </div> : null}
        <div className="display-all">
            <p>Display all alarms?</p>
            <input type="radio" name="display" value="Yes" onChange={handleDisplay}/>
            <label htmlFor="display">Yes</label>
            <input type="radio" name="display" value="No" onChange={handleDisplay}/>
            <label htmlFor="display">No</label>
        </div>
        <div className="school-schedules">
            {data.map((item, index) => {
            const indexActive = index === activeIndex;

            newRefs.push(newRef);
            return(
        <div ref={newRef} className={isActive || indexActive ? `school-header${index+1}` : "school-header"} onClick={(e) => handleToggle(e, index, item)}>
            <div className="btn btn-school">
            <h2>{item.name}</h2>
            <img src={chevron} className={isActive || indexActive ? "open" : "collapse"} alt="chevron"/>
            </div>
            <div className={isActive || indexActive ? "past-alarms active" : "hide"}>
            {item.prev[0] && item.prev.length > 0 &&<ListItem item={item.prev[0]}/>}
            </div>
            <div className={isActive || indexActive ? "next-alarm active" : "hide"}>
              {item.next[0] && item.next.length > 0 && <ListItem item={item.next[0]}/>}
            </div>
            <div className={isActive || indexActive ? "upcoming-alarms active" : "hide"}>
            {item.next.length > 0 ? (
          <div className={isActive || indexActive ? "active" : "hide"}> 
            {item.next.map((item, index) => index < (isDesktop ? 8 : 5) && index > 0 && <ListItem item={item} key={index} />)}
            <div className="bell">
              <audio ref={item.ref} loop={false} src={item.bell}>
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        ) : (
          <div className={isActive || indexActive ? "active" : "hide"}> 
            {item.all.map((alarm, i) => i < (isDesktop ? 8 : 5) && <ListItem item={alarm} key={i} />)}
            {/* <div className="bell">
              <audio ref={alarmBell2} loop={true} src={bell2}>
                Your browser does not support the audio element.
              </audio>
            </div> */}
          </div>
        )}
        </div>
        </div>
)})}
        </div>
    </div>)
}

export default School2;