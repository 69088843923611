import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import stop from "../Assets/Images/stop.png";
import play from "../Assets/Images/play.png";
import bell1 from '../Assets/Audio/animals.mp3';
import Stopwatch from "../Components/Stopwatch";

const Home = (props) => {
    const [key, setKey] = useState(0);
    const [task, setTask] = useState(""); //name of task
    const [timerCount, setTimerCount] = useState(1);
    const [countdown, setCountdown] = useState({minutes: 0, seconds: 0});
    // const [isZero, setIsZero] = useState(false);    
    const [isZero2, setIsZero2] = useState(true);
    const [isZero3, setIsZero3] = useState(true);
    const [duration, setDuration] = useState(0);
    // const [isPlaying, setIsPlaying] = useState(false);
    // const alarmBell = useRef(new Audio(bell1));
    // const alarmBell = useRef(null);
    // const alarmBell = useRef();
    // const alarmBell = useRef(bell1);
    // let alarmBackup = createRef();
    const [alarmBackup, setAlarmBackup] = useState(new Audio(bell1)); //kept browser from rerendering new audio file that the stop button could not pause; so ref was not needed.
    // const alarmBackup = new Audio(bell1);
    // alarmBackup.loop = true;

    useEffect(() => {
      document.title = `${props.title} ${props.now}`;
    })

    // useEffect(() => {
    //   alarmBackup = new Audio(bell1);
    //   return alarmBackup;
    // },[])
    
    // backup still plays regardless of reload
    // const handleRefresh = (e) => {
    //   window.location.reload();
    // }

     const playAudio = () => {
      // alarmBackup.play();
      // setIsPlaying(true);
      alarmBackup.play();
      // setAlarmBackup(new Audio(bell1));
      // console.log("backup play")
      // alarmBell.current.play();
      // console.log("ref play");
      // alarmBackup.play();
      // if (alarmBell.current !== null) {
      //   alarmBell.current.play();
      //   setIsPlaying(true);
      //   console.log("audio playing");
      // } else {
      //   alarmBackup.play();
      //   setIsPlaying(true);
      //   console.log("backup playing");
      // }
    }

    const handleStop = () => {
      // alarmBackup.pause();
      // console.log("backup pausing"); 
        // const backup = document.getElementById("backup");
        // backup.pause();
        // console.log("backup pausing"); 
        // alarmBell.current.pause();
        // alarmBackup.pause();
        
        // setIsPlaying(false);
        // alarmBell.current.pause();
        alarmBackup.pause();
        // console.log("backup pause")
        // alarmBell.current.pause();
        // console.log("ref pause");
        setAlarmBackup(new Audio(bell1));
      // if (alarmBell.current !== null) {
      //   alarmBell.current.pause();
      //   alarmBackup.pause();
      //   setIsPlaying(false);
      //   console.log("audio pausing");
      // } else {
      //   alarmBackup.pause();
      //   setIsPlaying(false);
      //   // const backup = document.getElementById("backup");
      //   // backup.pause();
      //   console.log("backup pausing"); 
      // }
      // else {
      //   alarmBackup.pause();
      //   console.log("backup pausing"); 
      // }
    }
    // useEffect(() => {
    //   if (isPlaying) {
    //     // alarmBackup.play();
    //     alarmBell.current.play(); //sometimes object undefined error, then blank screen in browser; works beautifully when object is defined.
    //     // alarmBell === null ? alarmBackup.play() : alarmBell.current.play();
    //     console.log("use effect play");
    //   } else {
    //     // alarmBackup.pause();
    //     alarmBell.current.pause(); //sometimes object undefined error, then blank screen in browser; works beautifully when object is defined.
    //     // alarmBell === null ? alarmBackup.pause() : alarmBell.current.pause();
    //     console.log("use effect pause");
    //   }
    // }, [isPlaying]);
   

    const handleAddTimer = (e) => {
      e.preventDefault();
      // const count = [...timerCount];
      // const newNum = +timerCount.pop()+1
      // count.push(newNum);
      const count = +timerCount + 1;
      setTimerCount(count);
      console.log(count);
    }

    const handleSubtractTimer = (e) => {
      e.preventDefault();
      // const count = [...timerCount];
      // const newNum = +timerCount.pop()-1
      // count.pop(newNum);
      const count = +timerCount - 1;
      setTimerCount(count);
      console.log(count);
    }

    const handleTask = (e) => {
      setTask(e.target.value);
    }

    const handleSelect = (e) => {
      e.target.select();
    }

    const handleChange = (e) => {
        const value = e.target.value;
        // if (typeof +value === "NaN") {
        //   setCountdown({...countdown, [e.target.name]: value});
        // } else {
          // console.log(countdown);
          setCountdown({...countdown, [e.target.name]: +value});
          // setIsZero(false);
          // console.log(isZero2, isZero3)
        // }
    }

    const handleRestart = () => {
      setKey(prevKey => prevKey + 1);
      // console.log(countdown);
      const totalTime = countdown.minutes * 60 + countdown.seconds;
      const totalTimeMs = totalTime*1000;
      // console.log(totalTime, totalTimeMs);
      setDuration(totalTime);
      setCountdown({minutes: 0, seconds: 0});
      setTimeout(playAudio, totalTimeMs);
    }

    const renderTime = ({ remainingTime }) => {
      // console.log({remainingTime});
        if (remainingTime > 0) {
          // setIsZero(false);
            let minutes = remainingTime >= 60 ? Math.floor(remainingTime / 60) : 0;
            let seconds = remainingTime > 60 && remainingTime % 60 < 10 ? `0${remainingTime % 60}`: remainingTime % 60 !== 0 && remainingTime >= 10 ? remainingTime % 60 : `0${remainingTime % 60}`;
            if (seconds === "00" && remainingTime===0) {
              setCountdown({...countdown, seconds: 0});
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            return (
                <div className="timer">
                <div className="text-bold">{task}</div>
                <div className="text">Remaining</div>
                <div className="value">{minutes}:{seconds}</div>
                <div className="text">MM:SS</div>
                {/* <div className="bell">
                    <audio ref={alarmBell} loop={true} src={bell1}>
                      Your browser does not support the audio element.
                    </audio>
                </div> */}
                </div>
            );
        }
        if (remainingTime === 0) {
            // setIsZero(true);
            // console.log(isZero2, isZero3)
            return <div className="timer">
                 <input name="task" type="text" className="stopwatch-field task" placeholder="task" value={task} onClick={handleSelect} onChange={handleTask}/>
                 <br/>
                {/* <input type="number" className="stopwatch-field" placeholder="HH"/> :  */}
                <input name="minutes" type="number" min={0} max={60} className="stopwatch-field" placeholder="MM" onChange={handleChange} />{` : `} 
                <input name="seconds" type="number" min={0} max={59} className="stopwatch-field" placeholder="SS" onChange={handleChange} required/>
                <br/>
                {/* {!countdown.minutes && !countdown.seconds ? <button className="btn" onClick={handleRestart} disabled>Start</button> : <button className="btn" onClick={handleRestart}>Start</button>} */}
                 {!countdown.minutes && !countdown.seconds 
                 ? 
                 <span>
                  <div className="bell">
                    <audio 
                    // ref={alarmBell} 
                    id="backup" loop={true} src={bell1}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <img src={stop} alt="stop" onClick={handleStop}/>
                  <img className="disabled" src={play} alt="play disabled"/>
                  </span> 
                  : <span>
                  <div className="bell">
                    <audio 
                    // ref={alarmBell} 
                    loop={true} src={bell1}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <img className="disabled" src={stop} alt="play"/>
                  <img src={play} alt="play" onClick={handleRestart}/>
                  </span>}
            </div>;
        }
     };

    return (
    <div className="stopwatches-section">
      <div className="stopwatches-wrapper">
      {/* <button className="btn" onClick={handleRefresh}>View Alarms</button> */}
      <div className="timer-wrapper">
        <CountdownCircleTimer
          key={key}
          isPlaying
          duration={duration}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[duration*3/4, duration/2, duration/5, 0]}
          onComplete={() => [false, 1000]}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
      {/* attempts to add multiple timers */}
      {timerCount >= 2 && 
      <Stopwatch setIsZero={setIsZero2}/>
      }
      {timerCount === 3 && 
      <Stopwatch setIsZero={setIsZero3} />
      }
      </div>
      {timerCount === 1 ? 
      <div className="add-subtract-timers">
        <button className="btn add-timer disabled" disabled onClick={handleSubtractTimer}>-</button>
       <button className="btn add-timer" onClick={handleAddTimer}>+</button>
       </div> : timerCount === 2 && isZero2 === true ? 
       <div>
       <button className="btn add-timer" onClick={handleSubtractTimer}>-</button>
       <button className="btn add-timer"  onClick={handleAddTimer}>+</button>
       </div> : timerCount === 2 && isZero2 === false ?
       <div>
         <button className="btn add-timer disabled" disabled onClick={handleSubtractTimer}>-</button>
        <button className="btn add-timer" onClick={handleAddTimer}>+</button>
        </div> : timerCount === 2 && isZero3 === false ? null 
        : timerCount === 3 && isZero3 === true ?
        <div>
       <button className="btn add-timer" onClick={handleSubtractTimer}>-</button>
       <button className="btn add-timer disabled" disabled onClick={handleAddTimer}>+</button>
       </div> : 
        null
       }
    </div>
    );
}

export default Home;