//nomenclature for school year variables - person(s) - Year - Weekday(s) (plural only for Monday/Friday) - work/holiday (Monday/Friday)/AMPM
export const kidsYMFwork = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'English Reading'},{time: '9:45 AM', task: 'Writing Journal'},{time: '10:15 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:30 AM', task: 'Independent Study'},{time: '11:00 AM', task: 'Penmanship'},{time: '11:30 AM', task: 'Lunch'},{time: '12:00 PM', task: 'Chinese Reading'},{time: '12:30 PM', task: 'Chinese Writing'},{time: '1:00 PM', task: 'Backyard Sketching'},{time: '1:30 PM', task: 'Backyard PE'},{time: '2:00 PM', task: "Daily Report (5mins): 1. What I Learned; 2. What I Enjoyed; 3. What's Next. Wed/Fri: Deliver; Thu: Write, turn in"},{time: '2:15 PM', task: 'Tidying/Home Care, Prep Backpack'},{time: '2:30 PM', task: 'Reward Earned?'},];

export const momYMFwork = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo,Daimoku'},{time: '8:30 AM', task: 'Morning Meeting,Breakfast Snack'},{time: '9:00 AM', task: 'Work'},{time: '10:15 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:30 AM', task: 'Work'},{time: '11:30 AM', task: 'Lunch'},{time: '12:00 PM', task: 'Work'},{time: '12:30 PM', task: 'Work'},{time: '1:00 PM', task: 'Work'},{time: '1:30 PM', task: 'Backyard PE'},{time: '2:00 PM', task: "W/F: Hear Reports - What kids learned, enjoyed, what's next;Thurs: Read Reports"},{time: '2:15 PM', task: 'Monitor Tidying, Snack Buck Earned?CSD Data?'},{time: '2:30 PM', task: 'Work'},{time: '3:00 PM', task: 'drop off kids'},];

export const fengYMFholiday = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '9:30 AM', task: 'Homework: - Math - English writing'},{time: '10:00 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '11:15 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '11:45 AM', task: 'Tidy Room Task'},{time: '12:15 PM', task: 'Lunch prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care'},{time: '1:45 PM', task: 'Reward: Tablet'},{time: '2:15 PM', task: 'Screen-free enrichment'},{time: '2:45 PM', task: 'Prep backpack'},{time: '2:55 PM', task: "Travel to the other parent's place"},{time: '3:00 PM', task: "Arrive at the other parent's place"},];

export const jieYMFholiday = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '9:30 AM', task: 'Homework: - Math - English writing'},{time: '10:00 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Tidy Room Task'},{time: '11:15 AM', task: 'Independent Study'},{time: '11:45 AM', task: 'Independent Study'},{time: '12:15 PM', task: 'Lunch prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care'},{time: '1:45 PM', task: 'Reward: Tablet'},{time: '2:15 PM', task: 'Screen-free enrichment'},{time: '2:45 PM', task: 'Prep backpack'},{time: '2:55 PM', task: "Travel to the other parent's place"},{time: '3:00 PM', task: "Arrive at the other parent's place"},];

export const haoYMFholiday = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Math - English writing'},{time: '9:30 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '10:00 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '11:15 AM', task: 'Tidy Room Task'},{time: '11:45 AM', task: 'Independent Study'},{time: '12:15 PM', task: 'Lunch prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care'},{time: '1:45 PM', task: 'Reward: Tablet'},{time: '2:15 PM', task: 'Screen-free enrichment'},{time: '2:45 PM', task: 'Prep backpack'},{time: '2:55 PM', task: "Travel to the other parent's place"},{time: '3:00 PM', task: "Arrive at the other parent's place"},];

export const momYMFholiday = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Tutor Feng Lan'},{time: '9:30 AM', task: 'Tutor Hao Ran'},{time: '10:00 AM', task: 'Tutor Jie Yi'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Tidy Room with Jie Yi'},{time: '11:15 AM', task: 'Tidy Room with Hao Ran'},{time: '11:45 AM', task: 'Tidy room with Feng Lan'},{time: '12:15 PM', task: 'Lunch prep with kids'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care Monitoring'},{time: '1:45 PM', task: 'Tidy Room checks - reward earned? Home Care check - snack bucks earned?'},{time: '2:15 PM', task: 'Collect devices'},{time: '2:55 PM', task: "Travel to the other parent's place"},];

export const fengYW = [{time: '3:30 PM', task: 'Shoes/bags/coats away in room'},{time: '3:35 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '3:45 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '3:55 PM', task: 'Homework prep: - homework paper - pencil, eraser out - desk to write on'},{time: '4:00 PM', task: 'Homework:  - Chinese/English reading - Reading log'},{time: '4:30 PM', task: 'Homework: - Math - English writing'},{time: '5:00 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '5:30 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '6:00 PM', task: 'Jim - group play time'},{time: '7:00 PM', task: 'Dinner prep:  - wash hands with soap  - plan a balanced meal  - make meal with Mom  - help set the table'},{time: '7:30 PM', task: 'Dinner'},{time: '8:00 PM', task: 'Home Care'},{time: '8:30 PM', task: 'Tidy Room Task'},{time: '8:45 PM', task: 'Reward:  - English reading  - Reading log'},{time: '9:10 PM', task: 'Hygiene starts with me!    1) Floss    2) Brush teeth    3) Potty    4) Wash hands with soap'},{time: '9:15 PM', task: 'Lights out!'},]

export const jieYW = [{time: '3:30 PM', task: 'Shoes/bags/coats away in room'},{time: '3:35 PM', task: 'Homework prep: - homework paper - pencil, eraser out - desk to write on'},{time: '3:45 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '3:55 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '4:00 PM', task: 'Homework: - Math - English writing'},{time: '4:30 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '5:00 PM', task: 'Homework:  - Chinese/English reading - Reading log'},{time: '5:30 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '6:00 PM', task: 'Jim - group play time'},{time: '7:00 PM', task: 'Tidy Room Task'},{time: '7:30 PM', task: 'Dinner'},{time: '8:00 PM', task: 'Home Care'},{time: '8:30 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:45 PM', task: 'Reward:  - English reading  - Reading log'},{time: '9:15 PM', task: 'Lights out!'},];

export const haoYW = [{time: '3:30 PM', task: 'Shoes/bags/coats away in room'},{time: '3:35 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '3:45 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '3:55 PM', task: 'Laundry Day and Task List - laundry in washer'},{time: '4:00 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '4:30 PM', task: 'Homework: - Math - English writing'},{time: '5:00 PM', task: 'Jim - 1-on-1 play time'},{time: '5:55 PM', task: 'Laundry Day and Task List - laundry in dryer'},{time: '6:00 PM', task: 'Jim - group play time'},{time: '7:00 PM', task: 'Homework:  - Science  - Music  - Social Studies'},{time: '7:30 PM', task: 'Dinner'},{time: '8:00 PM', task: 'Tidy Room Task,Laundry Day and Task List  - laundry sort and fold'},{time: '8:30 PM', task: 'Home Care'},{time: '8:45 PM', task: 'Reward:  - English reading  - Reading log'},{time: '9:10 PM', task: 'Hygiene starts with me!    1) Floss    2) Brush teeth    3) Potty    4) Wash hands with soap'},{time: '9:15 PM', task: 'Lights out!'},];

export const momYW = [{time: '3:30 PM', task: 'Shoes/bags/coats away'},{time: '3:35 PM', task: 'Schedules up on screens'},{time: '3:45 PM', task: 'Lunch + snack prep for next day'},{time: '3:55 PM', task: 'Laundry Day and Task List Monitor Hao Ran - laundry in washer'},{time: '4:00 PM', task: 'Tutor Hao Ran'},{time: '4:30 PM', task: 'Tutor Jie Yi'},{time: '5:00 PM', task: 'Tutor Feng Lan'},{time: '5:55 PM', task: 'Laundry Day and Task List Monitor Hao Ran - laundry in dryer'},{time: '6:00 PM', task: 'Jim - group play time'},{time: '7:00 PM', task: 'Dinner prep with Feng Lan'},{time: '7:30 PM', task: 'Dinner'},{time: '8:00 PM', task: 'Tidy Room with Hao Ran,Laundry Day and Task List Monitor Hao Ran  - laundry sort and fold'},{time: '8:30 PM', task: 'Tidy Room with Feng Lan'},{time: '8:45 PM', task: 'Tidy Room checks - reward earned?Collect screens'},{time: '9:10 PM', task: 'Home Care check - snack bucks earned?'},{time: '9:15 PM', task: 'Hygiene starts with me!    1) Floss    2) Brush teeth    3) Potty    4) Wash hands with soap'},{time: '9:20 PM', task: 'Lights out!'},]

export const girlsYRam = [{time: '7:30 AM', task: 'Wake up, floss, brush teeth'},{time: '7:35 AM', task: 'Breakfast'},{time: '7:50 AM', task: 'Morning gongyo, daimoku'},{time: '8:00 AM', task: 'Go to school'},{time: '3:00 PM', task: 'Pickup time'},];

export const haoYRam = [{time: '7:30 AM', task: 'Wake up, floss, brush teeth'},{time: '7:35 AM', task: 'Eye Exercises'},{time: '7:40 AM', task: 'Breakfast prep, eat'},{time: '7:50 AM', task: 'Morning gongyo, daimoku'},{time: '8:00 AM', task: 'Go to school'},{time: '3:00 PM', task: 'Pickup time'},];

export const momYRam = [{time: '7:00 AM', task: 'Wake up, morning daimoku, make breakfast'},{time: '7:30 AM', task: 'Floss, brush teeth'},{time: '7:35 AM', task: 'Eye Exercises Monitoring'},{time: '7:40 AM', task: 'Breakfast prep, eat'},{time: '7:50 AM', task: 'Morning gongyo, daimoku'},{time: '8:00 AM', task: 'School dropoff'},{time: '10:45 AM', task: 'Grocery pickup 2nd/4th Thursdays'},{time: '3:00 PM', task: 'School pickup'},];

export const fengYR = [{time: '3:30 PM', task: 'Shoes/bags/coats away in room'},{time: '3:35 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '3:45 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '3:55 PM', task: 'Homework prep: - homework paper - pencil, eraser out - desk to write on'},{time: '4:00 PM', task: 'Homework: - Math - English writing'},{time: '4:30 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '5:00 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '5:30 PM', task: 'Homework:  - Chinese/English reading - Reading log'},{time: '6:20 PM', task: 'Dinner'},{time: '6:50 PM', task: 'Tidy Room Task'},{time: '7:20 PM', task: 'Home Care'},{time: '8:45 PM', task: 'Reward: - Chinese/English reading - Reading log'},{time: '9:10 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:15 PM', task: 'Lights out!'},];

export const jieYR = [{time: '3:30 PM', task: 'Shoes/bags/coats away in room'},{time: '3:35 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '3:45 PM', task: 'Laundry Day and Task List - laundry in washer'},{time: '3:55 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '4:00 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '4:30 PM', task: 'Homework: - Math - English writing'},{time: '5:00 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '5:30 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '6:00 PM', task: 'Laundry Day and Task List - laundry in dryer'},{time: '6:20 PM', task: 'Dinner'},{time: '6:50 PM', task: 'Home Care'},{time: '7:20 PM', task: 'Tidy Room Task'},{time: '7:50 PM', task: 'Laundry Day and Task List - laundry sort and fold'},{time: '8:45 PM', task: 'Reward: - Chinese/English reading - Reading log'},{time: '9:10 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:15 PM', task: 'Lights out!'},];

export const haoYR = [{time: '3:30 PM', task: 'Shoes/bags/coats away in room'},{time: '3:35 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '3:45 PM', task: 'Eye Exercises'},{time: '3:55 PM', task: 'CSD Activity Prep'},{time: '4:00 PM', task: 'CSD Activity'},{time: '6:00 PM', task: 'CSD pickup'},{time: '6:05 PM', task: 'Shoes/bags/coats away in room'},{time: '6:10 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '6:20 PM', task: 'Dinner'},{time: '6:50 PM', task: 'Home Care'},{time: '7:20 PM', task: 'Homework: - Math  - English'},{time: '7:50 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '8:30 PM', task: 'Tidy Room Task'},{time: '8:45 PM', task: 'Homework: - Science - Music - Social Studies'},{time: '9:10 PM', task: 'Reward: - Chinese/English reading - Reading log'},{time: '9:30 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:35 PM', task: 'Lights out!'},];

export const momYR = [{time: '3:30 PM', task: 'Shoes/bags/coats away'},{time: '3:35 PM', task: 'Schedules up on screens'},{time: '3:45 PM', task: 'Eye Exercises Monitoring, Laundry Day and Task List Monitor Jie Yi - laundry in washer'},{time: '3:55 PM', task: 'CSD dropoff'},{time: '4:00 PM', task: 'Tutor Jie Yi'},{time: '4:30 PM', task: 'Tutor Feng Lan'},{time: '5:30 PM', task: 'Dinner prep with Jie Yi, Laundry Day and Task List Monitor Jie Yi - laundry in dryer'},{time: '6:00 PM', task: 'CSD pickup'},{time: '6:05 PM', task: 'Shoes/bags/coats away'},{time: '6:10 PM', task: 'Lunch + snack prep for next day'},{time: '6:20 PM', task: 'Dinner'},{time: '6:50 PM', task: 'Tidy Room with Feng Lan'},{time: '7:20 PM', task: 'Tidy Room with Jie Yi, Laundry Day and Task List Monitor Jie Yi - laundry sort and fold'},{time: '7:50 PM', task: 'Tutor with Hao Ran'},{time: '8:30 PM', task: 'Tidy Room with Hao Ran'},{time: '8:45 PM', task: 'Tidy Room checks - reward earned?'},{time: '9:10 PM', task: 'Home Care check - snack bucks earned?'},{time: '9:15 PM', task: 'Collect screens'},{time: '9:30 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:35 PM', task: 'Lights out!'},];

export const girlsYFam = [{time: '7:30 AM', task: 'Wake up, floss, brush teeth'},{time: '7:35 AM', task: 'Breakfast'},{time: '7:50 AM', task: 'Morning gongyo, daimoku'},{time: '8:00 AM', task: 'Go to school'},{time: '1:00 PM', task: 'Pickup time'},];

export const haoYFam = [{time: '7:30 AM', task: 'Wake up, floss, brush teeth'},{time: '7:35 AM', task: 'Eye Exercises'},{time: '7:40 AM', task: 'Breakfast'},{time: '7:50 AM', task: 'Morning gongyo, daimoku'},{time: '8:00 AM', task: 'Go to school'},{time: '1:00 PM', task: 'Pickup time'},];

export const momYFam = [{time: '7:00 AM', task: 'Wake up, morning daimoku, make breakfast'},{time: '7:30 AM', task: 'Floss, brush teeth'},{time: '7:35 AM', task: 'Eye Exercises Monitoring'},{time: '7:40 AM', task: 'Breakfast'},{time: '7:50 AM', task: 'Morning gongyo, daimoku'},{time: '8:00 AM', task: 'School dropoff'},{time: '1:00 PM', task: 'School pickup'},];

export const fengYF = [{time: '1:30 PM', task: 'Shoes/bags/coats away in room'},{time: '1:35 PM', task: 'Laundry Day and Task List - laundry in washer'},{time: '1:45 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '1:55 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '2:00 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '2:30 PM', task: 'Homework: - Math - English writing'},{time: '2:55 PM', task: 'Laundry Day and Task List - laundry in dryer'},{time: '3:00 PM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '3:30 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '4:00 PM', task: 'Tidy Room Task'},{time: '4:30 PM', task: 'Laundry Day and Task List - laundry sort and fold'},{time: '5:00 PM', task: 'Dinner'},{time: '5:30 PM', task: 'Home Care'},{time: '6:00 PM', task: 'Reward: Tablet'},{time: '6:30 PM', task: 'Family Meeting'},{time: '7:00 PM', task: 'Family Fun'},{time: '8:55 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:00 PM', task: 'Lights out!'},];

export const jieYF = [{time: '1:30 PM', task: 'Shoes/bags/coats away in room'},{time: '1:35 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '1:45 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '1:55 PM', task: 'Homework prep: - homework paper - pencil, eraser out - desk to write on'},{time: '2:00 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '2:30 PM', task: 'Homework: - Math - English writing'},{time: '3:00 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '3:30 PM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '4:30 PM', task: 'Tidy Room Task'},{time: '5:00 PM', task: 'Dinner'},{time: '5:30 PM', task: 'Home Care'},{time: '6:00 PM', task: 'Reward: Tablet'},{time: '6:30 PM', task: 'Family Meeting'},{time: '7:00 PM', task: 'Family Fun'},{time: '8:55 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:00 PM', task: 'Lights out!'},];

export const haoYF = [{time: '1:30 PM', task: 'Shoes/bags/coats away in room'},{time: '1:35 PM', task: 'Shower - set 5 minute timer - worn clothes in hamper - shower - set 15min timer for fan - shut off fan'},{time: '1:45 PM', task: 'Snack - wash hands with soap - eat snack - clean up after snack - get timer'},{time: '1:55 PM', task: 'Eye Exercises'},{time: '2:00 PM', task: 'Homework: - Math - English writing'},{time: '2:30 PM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '3:00 PM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '3:30 PM', task: 'Tidy Room Task'},{time: '4:00 PM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '4:30 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '5:00 PM', task: 'Dinner'},{time: '5:30 PM', task: 'Home Care'},{time: '6:00 PM', task: 'Reward: Tablet'},{time: '6:30 PM', task: 'Family Meeting'},{time: '7:00 PM', task: 'Family Fun'},{time: '8:55 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:00 PM', task: 'Lights out!'},];

export const momYF = [{time: '1:30 PM', task: 'Shoes/bags/coats away'},{time: '1:35 PM', task: 'Laundry Day and Task List Monitor Feng Lan - laundry in washer, Schedules up on screens'},{time: '1:55 PM', task: 'Eye Exercises'},{time: '2:00 PM', task: 'Tutor Feng Lan'},{time: '2:30 PM', task: 'Tutor Hao Ran'},{time: '2:55 PM', task: 'Laundry Day and Task List Monitor Feng Lan - laundry in dryer'},{time: '3:00 PM', task: 'Tutor Jie Yi'},{time: '3:30 PM', task: 'Tidy Room with Hao Ran'},{time: '4:00 PM', task: 'Tidy Room with Feng Lan'},{time: '4:30 PM', task: 'Laundry Day and Task List Monitor Feng Lan - laundry sort and fold, Dinner prep with Hao Ran'},{time: '5:00 PM', task: 'Dinner'},{time: '5:30 PM', task: 'Tidy Room checks - reward earned?'},{time: '6:00 PM', task: 'Home Care check - snack bucks earned?'},{time: '6:30 PM', task: 'Family Meeting'},{time: '7:00 PM', task: 'Family Fun'},{time: '8:55 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:00 PM', task: 'Lights out!'},];

//this variable is also for Thursday/Friday/Saturday/Sunday off from school with no SGI meetings
export const fengYSatVacay = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '9:30 AM', task: 'Homework: - Math - English writing'},{time: '10:00 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:40 AM', task: 'Laundry Day and Task List - laundry in washer'},{time: '10:45 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '11:15 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '11:45 AM', task: 'Tidy Room Task'},{time: '12:10 PM', task: 'Independent Study OR Laundry Day and Task List - laundry in dryer'},{time: '12:15 PM', task: 'Lunch prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care'},{time: '1:45 PM', task: 'Laundry Day and Task List - laundry sort and fold'},{time: '2:00 PM', task: 'Reward: Tablet'},{time: '2:30 PM', task: 'Family Meeting'},{time: '3:00 PM', task: 'Family Fun'},{time: '6:00 PM', task: 'Shower'},{time: '6:30 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '7:00 PM', task: 'Dinner'},{time: '7:30 PM', task: 'Kitchen care - wipe table, sweep floor, wash dishes'},{time: '8:00 PM', task: 'Evening Gongyo'},{time: '8:30 PM', task: 'Screen-free Enrichment'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const jieYSatVacay = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '9:30 AM', task: 'Homework: - Math - English writing'},{time: '10:00 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:40 AM', task: 'Laundry Day and Task List - laundry in washer'},{time: '10:45 AM', task: 'Tidy Room Task'},{time: '11:15 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '11:45 AM', task: 'Independent Study'},{time: '12:10 PM', task: 'Laundry Day and Task List - laundry in dryer'},{time: '12:15 PM', task: 'Lunch prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care'},{time: '1:45 PM', task: 'Laundry Day and Task List - laundry sort and fold'},{time: '2:00 PM', task: 'Reward: Tablet'},{time: '2:30 PM', task: 'Family Meeting'},{time: '3:00 PM', task: 'Family Fun'},{time: '6:00 PM', task: 'Shower'},{time: '6:30 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '7:00 PM', task: 'Dinner'},{time: '7:30 PM', task: 'Kitchen care - wipe table, sweep floor, wash dishes'},{time: '8:00 PM', task: 'Evening Gongyo'},{time: '8:30 PM', task: 'Screen-free Enrichment'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const haoYSatVacay = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Math - English writing'},{time: '9:30 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '10:00 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:40 AM', task: 'Laundry Day and Task List - laundry in washer'},{time: '10:45 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '11:15 AM', task: 'Tidy Room Task'},{time: '11:45 AM', task: 'Independent Study'},{time: '12:10 PM', task: 'Laundry Day and Task List - laundry in dryer'},{time: '12:15 PM', task: 'Lunch prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care'},{time: '1:45 PM', task: 'Laundry Day and Task List - laundry sort and fold'},{time: '2:00 PM', task: 'Reward: Tablet'},{time: '2:30 PM', task: 'Family Meeting'},{time: '3:00 PM', task: 'Family Fun'},{time: '6:00 PM', task: 'Shower'},{time: '6:30 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '7:00 PM', task: 'Dinner'},{time: '7:30 PM', task: 'Kitchen care - wipe table, sweep floor, wash dishes'},{time: '8:00 PM', task: 'Evening Gongyo'},{time: '8:30 PM', task: 'Screen-free Enrichment'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const momYSatVacay = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack, Pickup/drop off Feng Lan, get pastries'},{time: '9:00 AM', task: 'Tutor Feng Lan'},{time: '9:30 AM', task: 'Tutor Hao Ran'},{time: '10:00 AM', task: 'Tutor Jie Yi'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:40 AM', task: 'Laundry Day and Task List Monitoring - laundry in washer'},{time: '10:45 AM', task: 'Tidy Room with Jie Yi'},{time: '11:15 AM', task: 'Tidy Room with Hao Ran'},{time: '11:45 AM', task: 'Tidy Room with Feng Lan'},{time: '12:10 PM', task: 'Laundry Day and Task List Monitoring - laundry in dryer'},{time: '12:15 PM', task: 'Lunch prep with kids'},{time: '12:30 PM', task: 'Pickup/drop off Feng Lan'},{time: '12:45 PM', task: 'Lunch'},{time: '1:15 PM', task: 'Home Care Monitoring'},{time: '1:45 PM', task: ' Laundry Day and Task List Monitoring - laundry sort and fold'},{time: '1:55 PM', task: 'Tidy Room checks - reward earned? Home Care check - snack bucks earned?'},{time: '2:30 PM', task: 'Family Meeting'},{time: '3:00 PM', task: 'Family Fun'},{time: '6:00 PM', task: 'Shower'},{time: '6:30 PM', task: 'Dinner prep with kids'},{time: '7:00 PM', task: 'Dinner'},{time: '7:30 PM', task: 'Kitchen care monitoring'},{time: '8:00 PM', task: 'Evening Gongyo'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const fengYSun = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '9:30 AM', task: 'Homework: - Math - English writing'},{time: '10:00 AM', task: 'Homework: - Chinese/English reading - Reading log'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '11:25 AM', task: 'ESD / JHHS Meeting prep'},{time: '11:30 AM', task: 'ESD / JHHS Meeting'},{time: '12:30 PM', task: 'Return laptop'},{time: '12:35 PM', task: 'Lunch'},{time: '1:00 PM', task: 'Home Care'},{time: '1:30 PM', task: 'Reward: Tablet'},{time: '2:00 PM', task: 'Family Meeting'},{time: '2:30 PM', task: 'Family Fun'},{time: '5:30 PM', task: 'Shower'},{time: '6:00 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '6:30 PM', task: 'Dinner'},{time: '7:00 PM', task: 'Kitchen care - wipe table, sweep floor, wash dishes'},{time: '7:30 PM', task: 'Evening Gongyo'},{time: '8:00 PM', task: 'Screen-free Enrichment'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const jieYSun = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '9:30 AM', task: 'Homework: - Math - English writing'},{time: '10:00 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Independent Study'},{time: '11:25 AM', task: 'ESD / JHHS Meeting prep'},{time: '11:30 AM', task: 'ESD / JHHS Meeting'},{time: '12:30 PM', task: 'Return laptop'},{time: '12:35 PM', task: 'Lunch'},{time: '1:00 PM', task: 'Home Care'},{time: '1:30 PM', task: 'Reward: Tablet'},{time: '2:00 PM', task: 'Family Meeting'},{time: '2:30 PM', task: 'Family Fun'},{time: '5:30 PM', task: 'Shower'},{time: '6:00 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '6:30 PM', task: 'Dinner'},{time: '7:00 PM', task: 'Kitchen care - wipe table, sweep floor, wash dishes'},{time: '7:30 PM', task: 'Evening Gongyo'},{time: '8:00 PM', task: 'Screen-free Enrichment'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const haoYSun = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Homework: - Math - English writing'},{time: '9:30 AM', task: 'Homework: - Chinese listening - Chinese speaking - Chinese writing'},{time: '10:00 AM', task: 'Homework: - Science - Music / Art - Social Studies'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:45 AM', task: 'Tidy Room Task'},{time: '11:25 AM', task: 'ESD / JHHS Meeting prep'},{time: '11:30 AM', task: 'ESD / JHHS Meeting'},{time: '12:30 PM', task: 'Return laptop'},{time: '12:35 PM', task: 'Lunch'},{time: '1:00 PM', task: 'Home Care'},{time: '1:30 PM', task: 'Reward: Tablet'},{time: '2:00 PM', task: 'Family Meeting'},{time: '2:30 PM', task: 'Family Fun'},{time: '5:30 PM', task: 'Shower'},{time: '6:00 PM', task: 'Dinner prep: - wash hands with soap - plan a balanced meal - make meal with Mom - help set the table'},{time: '6:30 PM', task: 'Dinner'},{time: '7:00 PM', task: 'Kitchen care - wipe table, sweep floor, wash dishes'},{time: '7:30 PM', task: 'Evening Gongyo'},{time: '8:00 PM', task: 'Screen-free Enrichment'},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];

export const momYSun = [{time: '7:55 AM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '8:00 AM', task: 'Morning Gongyo, Daimoku'},{time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack'},{time: '9:00 AM', task: 'Tutor Feng Lan'},{time: '9:30 AM', task: 'Tutor Hao Ran'},{time: '10:00 AM', task: 'Tutor Jie Yi, Parents Group Meeting'},{time: '10:30 AM', task: 'Break, Snack, Water, Stretch'},{time: '10:40 AM', task: 'Laundry Day and Task List - my laundry in washer'},{time: '10:45 AM', task: 'Tidy Room with Hao Ran'},{time: '11:25 AM', task: 'ESD / JHHS Meeting device prep'},{time: '11:30 AM', task: 'ESD / JHHS Meeting Monitoring'},{time: '11:55 AM', task: 'Laundry Day and Task List - my laundry in dryer'},{time: '12:00 PM', task: 'Lunch prep'},{time: '12:30 PM', task: 'Collect devices'},{time: '12:35 PM', task: 'Lunch'},{time: '1:00 PM', task: 'Home Care Monitoring'},{time: '1:05 PM', task: ' Laundry Day and Task List - my laundry sort and fold'},{time: '1:30 PM', task: 'Tidy Room checks - reward earned? Home Care check - snack bucks earned?'},{time: '2:00 PM', task: 'Family Meeting'},{time: '2:30 PM', task: 'Family Fun'},{time: '5:30 PM', task: 'Shower'},{time: '6:00 PM', task: 'Dinner prep with kids, Lunch + snack prep for next day'},{time: '6:30 PM', task: 'Dinner'},{time: '7:00 PM', task: 'Kitchen care monitoring'},{time: '7:30 PM', task: 'Evening Gongyo'},{time: '8:00 PM', task: ''},{time: '9:00 PM', task: 'Hygiene starts with me! 1) Floss 2) Brush teeth 3) Potty 4) Wash hands with soap'},{time: '9:05 PM', task: 'Lights out!'},];