import React, {useState, useEffect} from "react";

const English = (props) => {
    const [show, setShow] = useState("fiction");
    const [isActive, setIsActive] = useState(0);
    const english = ["fiction", "nonfiction", "writing", "speaking"];
  
    const handleClick = (event, index) => {
        //event target value, state to set className condition, and event classList toggle
        if (event.target.value) {
            setIsActive(index);
            event.currentTarget.classList.toggle("active");
        }
    };

    useEffect(() => {
        document.title = `${props.title} ${show.split(" ")[0].toUpperCase()} ${props.now}`;
      })

    return (
        <div className="english-section">
            <h1>English Prompts</h1>
            <section className="btn-spotlight">
                {english.map((item, i) => {
                    //button active using className condition
                    return (
                        <button key={i} className={ isActive === i ? "btn active" : "btn"} value={i} onClick={(e) => {
                            setShow(`${item}`);
                            handleClick(e, i);
                        }}>{item.toUpperCase()}</button>
                    )
                })}
            </section>
            <section className="english-prompts-wrapper">
            {show === "fiction" && <>
            <h2>English Fiction Skills</h2>
            <p>How do you know it's fiction?</p>	 
            <p>Who is the author?</p> 
            <p>What is the author's style?</p>
            <p>What is the author trying to tell you in this story?</p>
            <p>Tell me some words that the author uses to describe the setting.</p>
            <p>Share a place in the story where the author describes something well.</p>
            <p>Why do you think the author wrote this story?</p>
            <p>Why do you think the author chose this title?</p>
            <p>What do you like about how the author has written this story?</p>
            <h3>Setting</h3>
            <p>Where does this story take place?</p>
            <p>Does this story take place in multiple locations? If so, where else?</p>
            <p>What time period does this story take place?</p>
            <p>How much time passes in the story?</p>
            <p>How can you tell when and where this story takes place? Give some details.</p>
            <h3>Characters</h3>
            <p>Who are the main characters in the story?</p>
            <p>Can you describe their characteristics using color, size, shape, personality traits?</p>
            <p>What are they like?</p>
            <p>What do they want?</p>
            <p>Tell how a character changes throughout the story.</p>
            <p>How does a character from this book remind you of someone in your life?</p>
            <p>Who is the most interesting character and why?</p>
            <p>What did you learn from &lt;character&gt; in the story?</p>
            <p>Were the characters believable? Why or why not?</p>
            <p>Which character are you most like and why?</p>
            <h3>Problem</h3>
            <p>What got in the way of the characters getting what they wanted?</p>
            <p>How did the problem get worse?</p>
            <h3>Solution</h3>
            <p>What were some of the choices that the main character had to make?</p>
            <p>If you were the main character, what would you have done and why?</p>
            <p>How did the main character solve his/her/their problem?</p>
            <p>How did the characters solve their problem?</p>
            <h3>Plot</h3>
            <p>How were you reminded of something in your own life when you read this story?</p>
            <p>What did you wonder about when you read this story?</p>
            <p>Tell about the problem in the story.</p>
            <p>Tell about some of the important events in the story.</p>
            <p>What would happen if ____?</p>
            <p>What is this story trying to teach you?</p>
            <p>Summarize a single subject or theme of the story.</p>
            <h3>Lesson</h3>
            <p>Has to do with living a good life or being a good person</p>
            <p>What can we learn from this book?</p>
            <p>What advice would the characters give?</p>
            <h3>General</h3>
            <p>Can you show me a place where your thinking changed?</p>
            <p>Do you have any unanswered questions about the story?</p>
            <p>What do you think is most important about what you just read?</p>
            <h3>Mood</h3>
            <p>How did you feel while reading this book? Why?</p>
            <p>What was the most exciting/strange/funny/sad/confusing/etc… part of the story? Why?</p>
            <p>What do you remember most about the story?</p>
            </>}
            {show === "nonfiction" && <>
                <h2>English Nonfiction Skills</h2>
                <p>How do you know it is nonfiction?</p>
                <p>What do you know about this topic?</p>
                <p>Why is the topic important?</p>
                <p>What questions do you have about what you are reading/have read?</p>
                <p>What have you learned about this topic?</p>
                <p>Did the author include all of the important information that you were wondering about?</p>
                <p>What information was provided through illustrations, charts, maps, etc...?</p>
                <h3>Main Idea</h3>
                <p>What was this page/chapter/section mostly about?</p>
                <h3>Key Details</h3>
                <p>What did you learn about X?</p>
                <h3>Vocabulary</h3>
                <p>What expert words did you notice?</p>
                <p>How can you figure out what that word means?</p>
                <h3>Text Features</h3>
                <p>What does X teach you?</p>
                <h3>News Report</h3>
                <p>Pretend that you are a news reporter telling all about a story.</p>
                <p>Provide an oral report of the story that you just read.</p>
                <p>As a reporter, you will answer two questions from the audience about the details in the story.</p>
                <h3>Dictionary</h3>
                <p>One of the most fun parts of reading is learning new words.</p>
                <p>Write down two new words.</p>
                <p>Read the sentences in which those words appear.</p>
                <p>Guess the meaning of the words using context clues.</p>
                <p>Look up the definitions in a dictionary to see how close your guess is.</p>
                <p>The more words we learn, the better we read and the more we understand the story!</p>
            </>}
            {show === "writing" && <>
                <h2>English Writing Skills</h2>
                <h3>Writing Tips</h3>
                <p>Start each sentence with a capital letter.</p>
                <p>Indent at the beginning of each paragraph.</p>
                <p>End each sentence with an ending punctuation: . ? !</p>
                <p>Make sure there’s a space between each word.</p>
                <p>Leave a space between each sentence.</p>
                <h3>Ways to Brainstorm for Longer Writing</h3>
                <p>Start with an illustration.</p>
                <p>Add details with 5 senses.</p>
                <p>Act it out.</p>
            </>}
            {show === "speaking" && <>
                <h3>English Speaking Practice</h3>
                <p>Read as a family.</p>
                <p>Talk and converse.</p>
                <p>Ask questions!</p>
            </>}
            </section>
        </div>
    )
}

export default English;