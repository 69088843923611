import React, { useEffect, useState, useRef} from "react";
import { kidsWkDay, fengW, fengR, fengF, wkd } from "../Data/SchedulesData";
import ListItem from "../Components/ListItem";
// import stop from "../Assets/Images/stop.png";
// import play from "../Assets/Images/play.png";
import bell2 from "../Assets/Audio/applause.mp3";



const Feng = (props) => {
  // console.log(props); //day of week, hh:mm as string
  // console.log(props.hhMm, typeof props.hhMm);
  // props.now holds current time; if current time hh:mm:ss is equal to the alarm clock display hh:mm, then play audio.
  // const [hhMm, setHhMm] = useState(props.hhMm);
  const [alarmList, setAlarmList] = useState([]);
  const [prevAlarms, setPrevAlarms] = useState([]);
  const [nextAlarms, setNextAlarms] = useState([]);
  const [currentAlarm, setCurrentAlarm] = useState([]);
  // const [audioOn, setAudioOn] = useState(false);
  const alarmBell2 = useRef(new Audio(bell2));

  const playAudio = () => {
    // alarmBell2.current.load();
    alarmBell2.current.play();
    // setAudioOn(true);
    // console.log("play clicked")
  };

  // const handleStop = () => {
  //   alarmBell2.current.pause();
  // //   setAudioOn(false);
  // //   setCurrentAlarm([]);
  // //   console.log("pause clicked")
  // }

  // convert 12-hour to 24-hour time string cycle sans am/pm
  const checkTime = (timeStr) => {
    let hours = Number(timeStr.match(/^(\d+)/)[1]);
    let minutes = Number(timeStr.match(/:(\d+)/)[1]);
    let AMPM = timeStr.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    return hours + ":" + minutes;
  };

  const getList = (day) => {
    // console.log(day);
    let list = [];
    if (day === 3) {
      list = kidsWkDay.concat(fengW);
      //    console.log("W", list);
    } else if (day === 4) {
      list = kidsWkDay.concat(fengR);
      // console.log("R", list);
    } else if (day >= 6) {
      list = wkd;
      // console.log("Wkd", list);
    } else {
      list = kidsWkDay.concat(fengF);
      // console.log("MTF", list);
    }
    // console.log("final", list);
    setAlarmList(list);
  };

  const checkShift = (list, timeStr) => {
    // console.log("state", list);
    // let alarmListCopy = [...list];
    // console.log("copy", alarmListCopy);
    let timeOnlyArr = list.map((item) => checkTime(item.time)); //item sent in as string.
    // console.log(timeOnlyArr); //items are 24hr strings with only the time sans am/pm
    let prevArr = timeOnlyArr.filter((item) => item < timeStr);
    let start = list.slice(0, prevArr.length);
    let end = list.slice(prevArr.length);
    let startReverse = start.reverse();
    // console.log(startReverse, end);
    setPrevAlarms(startReverse);
    setNextAlarms(end);
    let currentTime = timeOnlyArr.filter((item) => item === timeStr);
    // console.log(currentTime);
    setCurrentAlarm(currentTime);
    if (currentTime.length > 0) {
      // setCurrentAlarm(currentTime);
      // setTimeout(playAudio, 3000); //needed me to click in ui in order for alarm to start.
      playAudio();
    } else {
      // handleStop(); //broke ui after alarm
      // setCurrentAlarm([]); //did not break ui after alarm
      return; //did not break ui after alarm
    }

    // let currentAlarm = end[0]; //12hr am/pm format
    // console.log(currentAlarm);
    // if (currentAlarm = checkTime())
  };

  //works locally, not when deployed
  // const handleRefresh = () => {
  //   window.location.reload();
  // } 

  useEffect(() => {
    document.title = `${props.title} ${props.now}`;
  //     // console.log(props.hhMm);
      getList(props.day);
  //     // setTimeout(
  //     // checkShift(alarmList, props.hhMm)
  //     // , 60000);
      // const checkAmPm = (list, hhMm) => {
      //     checkShift(list, hhMm);
      // }
      // checkAmPm(alarmList, props.hhMm);
      const interval = setInterval(checkShift(alarmList,props.hhMm), 1000);
      // console.log("hello");
      return () => {
        clearInterval(interval);
      };

  //     // if (currentAlarm.length > 0) {
  //     //     playAudio();
  //     // } else {
  //     //     handleStop();
  //     // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.now]);
    
  // const checkAmPm = (list, hhMm) => {
  //         checkShift(list, hhMm);
  // }

  // const checkAmPm = useCallback(() => {
  //   checkShift(alarmList,props.hhMm)
  // }, [alarmList, props.hhMm, checkShift]);

  // const memo = useMemo(() => checkShift(alarmList,props.hhMm), [alarmList,props.hhMm, checkShift]);

  return (
    <div>
      {/* <button className="btn" onClick={handleRefresh}>View Alarms</button> */}
      {/* <p>Alarms will appear in one minute. Please do not reload or refresh the site.</p> */}
      {/* <p>Please do not reload or refresh the site.</p> */}
    <div className="all-alarms">
      <div className="past-alarms">
        <h2>Past Alarms</h2>
        {prevAlarms[0] && prevAlarms.length > 0
          ? prevAlarms.map((item, key) => {
              return <ListItem key={key} item={item} />;
            })
          : "No past alarms"}
      </div>
      <div className="next-alarm">
        
        {currentAlarm && currentAlarm.length > 0 ? (
          <div>
            <h2>Current Alarm</h2>
            {nextAlarms[0] && <ListItem item={nextAlarms[0]} />}
            <div className="bell">
              <audio ref={alarmBell2} loop={true} src={bell2}>
                Your browser does not support the audio element.
              </audio>
            </div>
            <p>Alarm will ring for one minute.</p>
            {/* <img src={stop} alt="stop" onClick={handleStop}/>
                    <img src={play} alt="play" onClick={playAudio}/> 
                    because currentAlarm re-renders every second, even if stop is clicked, because of fresh rerender, the audio keeps playing. When leaving it alone to play its course, the site blanks out after the timer is done ringing. User needs to refresh their screen in order for the next alarm to appear on the site. Alos, the user has to click on the site in order for the alarm to actually ring. An event must be detected. */}
            {/* {audioOn && <span>
                        <img src={stop} alt="stop" onClick={handleStop}/>
                        <img src={play} alt="play" onClick={playAudio}/>
                    </span>} clicking still did not work */}
          </div>
        ) : (
          <div>
            <h2>Next Alarm</h2>
            {nextAlarms[0] ? (
              <ListItem item={nextAlarms[0]} />
            ) : (
              "See you tomorrow!"
            )}
          </div>
        )}
      </div>
      <div className="upcoming-alarms">
        <h2>Upcoming Alarms</h2>
        {nextAlarms.length <= 1
          ? "See you tomorrow!"
          : nextAlarms.map((item, key) => {
              if (key > 0) {
                return <ListItem key={key} item={item} />;
              } else {
                return null;
              }
            })}
      </div>
    </div>
    </div>
  );
};

export default Feng;
