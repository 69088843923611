export const math = {characters:"数学",pinyin:"shùxué",jyutping:"sou3 hok6",english:"mathematics"};

export const mathCategories = [{characters:"估算和运算",pinyin:"gūsuàn hé yùnsuàn",jyutping:"gu2 syun3 wo4 wan6 syun3",english:"rounding and operations"},{characters:"时间",pinyin:"shíjiān",jyutping:"si4 gaan3",english:"time"},{characters:"形状和图形",pinyin:"xíngzhuàng hé túxíng",jyutping:"se2",english:"shapes and graphs"},{characters:"分数",pinyin:"fēnshù",jyutping:"fan1 sou3",english:"fractions"},{characters:"公制单位",pinyin:"gōngzhì dānwèi",jyutping:"gung1 jai3 daan1 wai2",english:"metric unit"}];

export const roundingOperations = [{characters:"估算和运算",pinyin:"gūsuàn hé yùnsuàn",jyutping:"gu2 syun3 wo4 wan6 syun3",english:"rounding and operations"},{characters:"差",pinyin:"chà",jyutping:"cha1",english:"difference"},{characters:"估算",pinyin:"gūsuàn",jyutping:"gu2 syun3",english:"estimation"},{characters:"估算",pinyin:"gūsuàn",jyutping:"gu2 syun3",english:"rounding"},{characters:"和",pinyin:"hé",jyutping:"wo4",english:"sum"},{characters:"值",pinyin:"zhí",jyutping:"jik6",english:"value"},{characters:"位数",pinyin:"wèi shù",jyutping:"wai2 sou3",english:"place"},{characters:"乘积",pinyin:"chéngjī",jyutping:"sing4 jik1",english:"product"},{characters:"商",pinyin:"shāng",jyutping:"seung1",english:"quotient"}];

export const timeUnits = [{characters:"时间",pinyin:"shíjiān",jyutping:"si4 gaan3",english:"time"},{characters:"上午",pinyin:"shàngwǔ",jyutping:"seung6 ng5",english:"A.M."},{characters:"下午",pinyin:"xiàwǔ",jyutping:"ha6 ng5",english:"P.M."},{characters:"小时",pinyin:"xiǎoshí",jyutping:"siu2 si4",english:"hour"},{characters:"秒",pinyin:"miǎo",jyutping:"miu5",english:"second"},{characters:"分钟",pinyin:"fēnzhōng",jyutping:"fan1 jung1",english:"minute"}];

export const shapesGraphs = [{characters:"形状和图形",pinyin:"xíngzhuàng hé túxíng",jyutping:"se2",english:"shapes and graphs"},{characters:"平方英寸",pinyin:"píngfāng yīngcùn",jyutping:"ying4 jong6 wo4 tou4 ying4",english:"square inch"},{characters:"单位",pinyin:"dānwèi",jyutping:"ping4 fong1 ying1 chyun3",english:"unit"},{characters:"面积",pinyin:"miànjī",jyutping:"daan1 wai2",english:"area"},{characters:"条形图 / 柱状图",pinyin:"tiáo xíng tú/ zhùzhuàng tú",jyutping:"min6 jik1",english:"bar graph"},{characters:"线状分布图",pinyin:"xiàn zhuàng fēnbù tú",jyutping:"tiu4 ying4 tou4 /  chyu5 jong6 tou4",english:"line plot"},{characters:"图表",pinyin:"túbiǎo",jyutping:"sin3 jong6 fan1 bou3 tou4",english:"picture graph"},{characters:"四边形",pinyin:"sìbiānxíng",jyutping:"tou4 biu2",english:"quadrilateral"},{characters:"菱形",pinyin:"língxíng",jyutping:"ling4 ying4",english:"rhombus"},{characters:"直角",pinyin:"zhíjiǎo",jyutping:"jik6 gok3",english:"right angle"},{characters:"三角形",pinyin:"sānjiǎoxíng",jyutping:"saam1 gok3 ying4",english:"triangle"},{characters:"正方形",pinyin:"zhèngfāngxíng",jyutping:"jing3 fong1 ying4",english:"square"},{characters:"五边形",pinyin:"wǔ biān xíng",jyutping:"ng5 bin1 ying4",english:"pentagon"},{characters:"六边形",pinyin:"liù biān xíng",jyutping:"luk6 bin1 ying4",english:"hexagon"},{characters:"八边形",pinyin:"bā biān xíng",jyutping:"baat3 bin1 ying4",english:"octagon"},{characters:"平方厘米",pinyin:"píngfāng límǐ",jyutping:"ping4 fong1 lei4 mai5",english:"square centimeter"},{characters:"周长",pinyin:"zhōu cháng",jyutping:"jau1 cheung4",english:"perimeter"}];

export const fractions = [{characters:"分数",pinyin:"fēnshù",jyutping:"fan1 sou3",english:"fractions"},{characters:"分子",pinyin:"fēnzǐ",jyutping:"fan6 ji2",english:"numerator"},{characters:"分母",pinyin:"fēnmǔ",jyutping:"fan6 mou5",english:"denominator"},{characters:"相等分数",pinyin:"xiāngděng fēnshù",jyutping:"seung1 dang2 fan1 sou3",english:"equivalent fractions"}];

export const metricUnits = [{characters:"公制单位",pinyin:"gōngzhì dānwèi",jyutping:"gung1 jai3 daan1 wai2",english:"metric unit"},{characters:"克",pinyin:"kè",jyutping:"hak1",english:"gram"},{characters:"千克",pinyin:"qiānkè",jyutping:"chin1 hak1",english:"kilogram"},{characters:"升",pinyin:"shēng",jyutping:"sing1",english:"liter"},{characters:"毫升",pinyin:"háoshēng",jyutping:"hou4 sing1",english:"milliliter"}];