import React, {useState, useRef, useEffect} from "react";
import { chinese, categories, people, animals, things, places, plants, verbsBody, verbsBrain, colors, size, shapes, feelings, personality, times, locations, connectives, conjunctions } from "../Data/ChineseData";

const Chinese = (props) => {
    const [show, setShow] = useState("");
    const [showMore, setShowMore] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [subcatActive, setSubcatActive] = useState("");
    const [activeIndex, setActiveIndex] = useState("");

    let newRefs = [];
    const newRef = useRef(isActive);

    const handleCategoryClick = (event, index) => {
        //state in partnership with refs
        setIsActive(true);
        setActiveIndex(index);
        setSubcatActive("");
    };

    const handleSubcategoryClick = (event, index) => {
        //event target value, state to set className condition, and event classList toggle
        if (event.target.value) {
            setSubcatActive(index);
            event.currentTarget.classList.toggle("active");
        }
    };

    useEffect(() => {
        document.title = `${props.title} ${show.split(" ")[0].toUpperCase()} ${props.now}`;
    })

    return <div className="chinese-section">
        <h1>{chinese.characters} Chinese  <span className="romanization">{chinese.pinyin} / {chinese.jyutping}</span></h1>
        <section className="btn-spotlight">
            {categories.map((item, i) => {
                // button active using ref
                const indexActive = i === activeIndex;
                newRefs.push(newRef);
                return  <button ref={newRef} key={i} className={ indexActive ? "btn active" : "btn"} onClick={(e) => {
                    setShow(`${item.english}`);
                    setShowMore("");
                    handleCategoryClick(e, i);
            }}>{item.characters} {item.english.toUpperCase()}</button>
            })}
        </section>
        <section className={showMore ? "chinese-prompts-wrapper" : ""}>
            {categories.map((item, i) => show === `${item.english}` && (<div key={i}>
                <h2 className="chinese-characters">{item.characters}
                <span className="chinese-translation">{item.english.toUpperCase()}</span>
                <span className="romanization">{item.pinyin} / {item.jyutping}</span></h2>
                {item.subcategories && item.subcategories.map((subItem, j) => {
                    //button active using className condition
                    return (
                        <button key={j} className={ subcatActive === j ? "btn active" : "btn"} value={j} onClick={(e) => {
                            setShowMore(`${subItem.english}`);
                            handleSubcategoryClick(e, j);
                        }}>{subItem.characters} {subItem.english.toUpperCase()}</button>
                    )
                })}
                <div className="chinese-characters-wrapper">
                {showMore === "people" && 
                people.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "animals" && 
                animals.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "things" && 
                things.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "places" && 
                places.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "plants" && 
                plants.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "verb" && 
                verbsBody.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "movement in the head" && 
                verbsBrain.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "color" && 
                colors.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "size" && 
                size.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "shapes" && 
                shapes.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "feelings" && 
                feelings.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "personality" && 
                personality.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "time" && 
                times.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "location" && 
                locations.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "connectives" && 
                connectives.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                {showMore === "conjunctions" && 
                conjunctions.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                </div>
            </div>))}
            
        </section>
    </div>
}

export default Chinese;