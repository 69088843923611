import React from "react";

const ListItem = (props) => {
    // console.log(props);
    const item = props.item;
    return(
        <div className="alarm-item">
            <h3 className="alarm-time">{item.time}</h3>
            <p className="alarm-task">{item.task}</p>
        </div>
    )
}

export default ListItem;