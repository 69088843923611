import React, {useState, useEffect} from "react";
import { math, mathCategories, roundingOperations, timeUnits, shapesGraphs, fractions, metricUnits } from "../Data/MathData";

const Math = (props) => {
    const [show, setShow] = useState("rounding and operations");
    const [isActive, setIsActive] = useState(0);

    const handleClick = (event, index) => {
        //event target value, state to set className condition, and event classList toggle
        if (event.target.value) {
            setIsActive(index);
            event.currentTarget.classList.toggle("active");
        }
    };

    useEffect(() => {
        document.title = `${props.title} ${show.split(" ")[0].toUpperCase()} ${props.now}`;
    })
    
    return (
        <div className="math-section">
            <h1>{math.characters} Math  <span className="romanization">{math.pinyin} / {math.jyutping}</span></h1>
            <section className="btn-spotlight">
                {mathCategories.map((item, i) => {
                    //button active using className condition
                    return (
                        <button key={i} className={ isActive === i ? "btn active" : "btn"} value={i} onClick={(e) => {
                            setShow(`${item.english}`);
                            handleClick(e, i);
                        }}>{item.characters} {item.english.toUpperCase()}</button>
                    )
                })}
            </section>
            <section className={show ? "chinese-prompts-wrapper" : ""}>
            {mathCategories.map((item, j) => show === `${item.english}` && (<div key={j}>
                <h2 className="chinese-characters">{item.characters}
                <span className="chinese-translation">{item.english.toUpperCase()}</span>
                <span className="romanization">{item.pinyin} / {item.jyutping}</span></h2>
                <div className="chinese-characters-wrapper">
                    {show === "rounding and operations" && 
                    roundingOperations.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                    {show === "time" && 
                    timeUnits.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                    {show === "shapes and graphs" && 
                    shapesGraphs.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                    {show === "fractions" && 
                    fractions.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                    {show === "metric unit" && 
                    metricUnits.map((obj,k) => <p key={k} className="chinese-characters">{obj.characters}<span className="chinese-translation">{obj.english}</span><span className="romanization">{obj.pinyin} / {obj.jyutping}</span></p>)}
                </div>
            </div>))}
            </section>
        </div>
    )
}

export default Math;