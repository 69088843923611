import React, {useState} from "react";
import rafflePic from "../Assets/Images/raffle-draw.jpeg";

const FamilyFun = () => {
    const [activity, setActivity] = useState("");

const activities = ["Enjoy a family movie while eating any savory snacks", "Board games while eating any snacks", "Watching 2 hours of anime while eating any snacks", "Game console setup and family game","Play Cards Against Humanity"];


const drawActivity = (arr) => {
  let index = Math.floor(Math.random() * arr.length);
  console.log(arr[index]);
  setActivity(arr[index]);
}

return (
<div className="family-fun">
    <h1>Family Fun Activity</h1>
    <h3>Time to Draw!</h3>
    <p>Click on the raffle basket.</p>
    <img src={rafflePic} alt="raffle basket" className="borderRadius100P" onClick={() => drawActivity(activities)}/>
    <p className="bold fontSize20">{activity ? activity : null}</p>
</div>
)
}

export default FamilyFun;