//summer schedules data
export const kidsWkDay = [{ time: '8:00 AM', task: 'Morning Gongyo, Daimoku' }, { time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack' }, { time: '9:00 AM', task: 'Summer Reading' }, { time: '9:45 AM', task: 'Writing Journal' }, { time: '10:15 AM', task: 'Break, Snack, Water, Stretch' }, { time: '10:30 AM', task: 'Independent Study' }, { time: '11:00 AM', task: 'Penmanship' }, { time: '11:30 AM', task: 'Lunch' }, { time: '12:00 PM', task: 'Chinese Reading' }, { time: '12:30 PM', task: 'Chinese Writing' }, { time: '1:00 PM', task: 'Backyard Sketching' }, { time: '1:30 PM', task: 'Backyard PE' }, { time: '2:00 PM', task: "Daily Report (5mins): 1. What I Learned; 2. What I Enjoyed; 3. What's Next. Wed/Fri: Deliver; Thu: Write, turn in" }, { time: '2:15 PM', task: 'Tidying/Home Care, Prep Backpack' }, { time: '2:30 PM', task: 'Reward Earned?' },]

export const momWkDay = [{ time: '8:00 AM', task: 'Morning Gongyo,Daimoku' }, { time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack' }, { time: '9:00 AM', task: 'Work' }, { time: '10:15 AM', task: 'Break, Snack, Water, Stretch' }, { time: '10:30 AM', task: 'Work' }, { time: '11:30 AM', task: 'Lunch' }, { time: '12:00 PM', task: 'Work' }, { time: '12:30 PM', task: 'Work' }, { time: '1:00 PM', task: 'Work' }, { time: '1:30 PM', task: 'Backyard PE' }, { time: '2:00 PM', task: "W/F: Hear Reports - What kids learned, enjoyed, what's next; Thurs: Read Reports" }, { time: '2:15 PM', task: "Monitor Tidying, Snack Buck Earned? CSD Data?" }, { time: '2:30 PM', task: 'Work' }, { time: '3:00 PM', task: 'drop off kids' },]

export const fengW = [{ time: '3:00 PM', task: 'Shoes / bags / coats away' }, { time: '3:15 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '3:30 PM', task: 'Summer Reading' }, { time: '4:00 PM', task: 'Writing Journal' }, { time: '4:30 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Independent Study Consultation' }, { time: '5:30 PM', task: 'Tidy Up Study Space' }, { time: '6:00 PM', task: 'CSD Activity' }, { time: '6:45 PM', task: 'Dinner Prep - Wash Hands' }, { time: '7:00 PM', task: 'Dinner' }, { time: '8:00 PM', task: 'Home Care + Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const jieW = [{ time: '3:00 PM', task: 'Shoes / bags / coats away' }, { time: '3:15 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '3:30 PM', task: 'Summer Reading' }, { time: '4:00 PM', task: 'Writing Journal' }, { time: '4:30 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Tidy Up Study Space' }, { time: '5:30 PM', task: 'Independent Study Consultation' }, { time: '6:00 PM', task: 'CSD Activity' }, { time: '6:45 PM', task: 'Dinner Prep - Wash Hands' }, { time: '7:00 PM', task: 'Dinner' }, { time: '8:00 PM', task: 'Home Care + Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const haoW = [{ time: '3:00 PM', task: 'Shoes / bags / coats away' }, { time: '3:15 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '3:30 PM', task: 'Summer Reading' }, { time: '4:00 PM', task: 'Writing Journal' }, { time: '4:30 PM', task: 'Tidy Up Study Space + for CSD Activity' }, { time: '4:45 PM', task: 'Independent Study Consultation' }, { time: '5:00 PM', task: 'CSD Activity' }, { time: '6:45 PM', task: 'Dinner Prep - Wash Hands' }, { time: '7:00 PM', task: 'Dinner' }, { time: '8:00 PM', task: 'Home Care + Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const momW = [{ time: '3:00 PM', task: 'Work' }, { time: '3:15 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '3:30 PM', task: 'Work' }, { time: '4:45 PM', task: 'Independent Study Consultation - Ayo' }, { time: '5:00 PM', task: 'Independent Study Consultation - Nike' }, { time: '5:30 PM', task: 'Independent Study Consultation - Titi' }, { time: '6:00 PM', task: 'CSD Activity' }, { time: '6:45 PM', task: 'Dinner Prep - Wash Hands' }, { time: '7:00 PM', task: 'Dinner' }, { time: '8:00 PM', task: 'Home Care + Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const fengR = [{ time: '3:00 PM', task: 'Summer Reading' }, { time: '3:30 PM', task: 'Afternoon Meeting,Afternoon Snack' }, { time: '3:45 PM', task: 'Writing Journal' }, { time: '4:15 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Tidy Up Study Space' }, { time: '5:30 PM', task: 'Free Choice Activity' }, { time: '6:00 PM', task: 'Dinner' }, { time: '7:00 PM', task: 'Home Care + Tidy Room' }, { time: '8:00 PM', task: 'Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const jieR = [{ time: '3:00 PM', task: 'Summer Reading' }, { time: '3:30 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '3:45 PM', task: 'Writing Journal' }, { time: '4:15 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Tidy Up Study Space' }, { time: '5:30 PM', task: 'Free Choice Activity' }, { time: '6:00 PM', task: 'Dinner' }, { time: '7:00 PM', task: 'Home Care + Tidy Room' }, { time: '8:00 PM', task: 'Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const haoR = [{ time: '3:00 PM', task: 'Summer Reading' }, { time: '3:45 PM', task: 'Tidy Up Study Space, Prep for CSD' }, { time: '4:00 PM', task: 'CSD Check-In' }, { time: '6:00 PM', task: 'Dinner' }, { time: '7:00 PM', task: 'Home Care + Tidy Room' }, { time: '8:00 PM', task: 'Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const momR = [{ time: '3:00 PM', task: 'Work' }, { time: '3:30 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '3:45 PM', task: 'Work' }, { time: '4:00 PM', task: 'CSD Check-In' }, { time: '4:15 PM', task: 'Work' }, { time: '5:00 PM', task: 'Focus Prep Time' }, { time: '5:45 PM', task: 'CSD Pickup' }, { time: '6:00 PM', task: 'Dinner' }, { time: '7:00 PM', task: 'Monitor Tidying, Snack Buck Earned? CSD Data?' }, { time: '8:00 PM', task: 'Bedtime Routine' }, { time: '8:45 PM', task: 'Reward' }, { time: '9:00 PM', task: 'Lights Out' },]

export const fengF = [{ time: '3:00 PM', task: 'Summer Reading' }, { time: '3:30 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '4:00 PM', task: 'Writing Journal' }, { time: '4:30 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Tidy Up Study Space' }, { time: '5:30 PM', task: 'Free Choice Activity' }, { time: '6:00 PM', task: 'Dinner' }, { time: '6:30 PM', task: 'Home Care + Tidy Room' }, { time: '7:30 PM', task: 'Family Meeting' }, { time: '7:45 PM', task: 'Family Fun Activity' }, { time: '8:45 PM', task: 'Bedtime Routine' }, { time: '9:00 PM', task: 'Lights Out' },]

export const jieF = [{ time: '3:00 PM', task: 'Summer Reading' }, { time: '3:30 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '4:00 PM', task: 'Writing Journal' }, { time: '4:30 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Tidy Up Study Space' }, { time: '5:30 PM', task: 'Free Choice Activity' }, { time: '6:00 PM', task: 'Dinner' }, { time: '6:30 PM', task: 'Home Care + Tidy Room' }, { time: '7:30 PM', task: 'Family Meeting' }, { time: '7:45 PM', task: 'Family Fun Activity' }, { time: '8:45 PM', task: 'Bedtime Routine' }, { time: '9:00 PM', task: 'Lights Out' },]

export const haoF = [{ time: '3:00 PM', task: 'Summer Reading' }, { time: '3:30 PM', task: 'Afternoon Meeting,Afternoon Snack' }, { time: '4:00 PM', task: 'Writing Journal' }, { time: '4:30 PM', task: 'Math / Dreambox / Khan Academy' }, { time: '5:00 PM', task: 'Tidy Up Study Space, CSD Activity: PECs' }, { time: '5:30 PM', task: 'Free Choice Activity' }, { time: '6:00 PM', task: 'Dinner' }, { time: '6:30 PM', task: 'Home Care + Tidy Room' }, { time: '7:30 PM', task: 'Family Meeting' }, { time: '7:45 PM', task: 'Family Fun Activity' }, { time: '8:45 PM', task: 'Bedtime Routine' }, { time: '9:00 PM', task: 'Lights Out' },]

export const momF = [{ time: '3:00 PM', task: 'Work' }, { time: '3:30 PM', task: 'Afternoon Meeting, Afternoon Snack' }, { time: '4:00 PM', task: 'Work' }, { time: '5:00 PM', task: 'Focus Prep Time' }, { time: '6:00 PM', task: 'Dinner' }, { time: '6:30 PM', task: 'Monitor Tidying, Snack Buck Earned?  CSD Data?' }, { time: '7:30 PM', task: 'Family Meeting' }, { time: '7:45 PM', task: 'Family Fun Activity' }, { time: '8:45 PM', task: 'Bedtime Routine' }, { time: '9:00 PM', task: 'Lights Out' },]

export const wkd = [{ time: '8:00 AM', task: 'Morning Gongyo, Daimoku' }, { time: '8:30 AM', task: 'Morning Meeting, Breakfast Snack' }, { time: '9:00 AM', task: 'Summer Reading' }, { time: '9:45 AM', task: 'Writing Journal' }, { time: '10:15 AM', task: 'Break, Snack, Water, Stretch' }, { time: '10:30 AM', task: 'Independent Study' }, { time: '11:00 AM', task: 'Penmanship' }, { time: '11:30 AM', task: 'ESD/JHHS Meeting Buddhist Activity' }, { time: '12:00 PM', task: '' }, { time: '12:30 PM', task: 'Chinese Reading/Writing' }, { time: '1:00 PM', task: 'Backyard Sketching' }, { time: '1:30 PM', task: 'Backyard PE' }, { time: '2:00 PM', task: "Daily Report (5mins): 1. What I Learned; 2. What I Enjoyed; 3. What's Next" }, { time: '2:15 PM', task: 'Tidying/Home Care' }, { time: '2:30 PM', task: 'Reward Earned?' },]
