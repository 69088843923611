import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import stop from "../Assets/Images/stop.png";
import play from "../Assets/Images/play.png";
import bell1 from '../Assets/Audio/animals.mp3';

const Stopwatch = (props) => {
    const [key, setKey] = useState(0);
    const [task, setTask] = useState(""); //name of task
    const [countdown, setCountdown] = useState({minutes: 0, seconds: 0});
    const [duration, setDuration] = useState(0);
    const [alarmBackup, setAlarmBackup] = useState(new Audio(bell1)); //kept browser from rerendering new audio file that the stop button could not pause; so ref was not needed.

    useEffect(() => {
      document.title = `${props.title} ${props.now}`;
    })

     const playAudio = () => {
      alarmBackup.play();
    }

    const handleStop = () => {
        alarmBackup.pause();
        setAlarmBackup(new Audio(bell1));
    }

    const handleTask = (e) => {
      setTask(e.target.value);
    }

    const handleSelect = (e) => {
      e.target.select();
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setCountdown({...countdown, [e.target.name]: +value});
    }

    const handleRestart = () => {
      setKey(prevKey => prevKey + 1);
      // console.log(countdown);
      const totalTime = countdown.minutes * 60 + countdown.seconds;
      const totalTimeMs = totalTime*1000;
      // console.log(totalTime, totalTimeMs);
      setDuration(totalTime);
      setCountdown({minutes: 0, seconds: 0});
      setTimeout(playAudio, totalTimeMs);
    }

    const renderTime = ({ remainingTime }) => {
      // console.log({remainingTime});
        if (remainingTime > 0) {
          props.setIsZero(false);
            let minutes = remainingTime >= 60 ? Math.floor(remainingTime / 60) : 0;
            let seconds = remainingTime > 60 && remainingTime % 60 < 10 ? `0${remainingTime % 60}`: remainingTime % 60 !== 0 && remainingTime >= 10 ? remainingTime % 60 : `0${remainingTime % 60}`;
            if (seconds === "00" && remainingTime===0) {
              setCountdown({...countdown, seconds: 0});
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            return (
                <div className="timer">
                <div className="text-bold">{task}</div>
                <div className="text">Remaining</div>
                <div className="value">{minutes}:{seconds}</div>
                <div className="text">MM:SS</div>
                {/* <div className="bell">
                    <audio ref={alarmBell} loop={true} src={bell1}>
                      Your browser does not support the audio element.
                    </audio>
                </div> */}
                </div>
            );
        }
        if (remainingTime === 0) {
          props.setIsZero(true);
            return <div className="timer">
                 <input name="task" type="text" className="stopwatch-field task" placeholder="task" value={task} onClick={handleSelect} onChange={handleTask}/>
                 <br/>
                <input name="minutes" type="number" min={0} max={60} className="stopwatch-field" placeholder="MM" onChange={handleChange} />{` : `} 
                <input name="seconds" type="number" min={0} max={59} className="stopwatch-field" placeholder="SS" onChange={handleChange} required/>
                <br/>
                 {!countdown.minutes && !countdown.seconds 
                 ? 
                 <span>
                  <div className="bell">
                    <audio 
                    id="backup" loop={true} src={bell1}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <img src={stop} alt="stop" onClick={handleStop}/>
                  <img className="disabled" src={play} alt="play disabled"/>
                  </span> 
                  : <span>
                  <div className="bell">
                    <audio 
                    loop={true} src={bell1}>
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <img className="disabled" src={stop} alt="play"/>
                  <img src={play} alt="play" onClick={handleRestart}/>
                  </span>}
            </div>;
        }
     };

    return (
    <div className="stopwatch">
      <div className="timer-wrapper">
        <CountdownCircleTimer
          key={key}
          isPlaying
          duration={duration}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[duration*3/4, duration/2, duration/5, 0]}
          onComplete={() => [false, 1000]}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    </div>
    );
}

export default Stopwatch;