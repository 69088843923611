import React, {useEffect, useState, useRef} from "react";
import { momWkDay, momW, momR, momF, wkd } from "../Data/SchedulesData";
import ListItem from "../Components/ListItem";
import bell6 from '../Assets/Audio/seabirds.mp3';

const Mom = (props) => {

    const [alarmList, setAlarmList] = useState([]);
    const [prevAlarms, setPrevAlarms] = useState([]);
    const [nextAlarms, setNextAlarms] = useState([]);
    const [currentAlarm, setCurrentAlarm] = useState([]);
    const alarmBell6 = useRef(new Audio(bell6));

    const playAudio = () => {
        // alarmBell2.current.load();
        alarmBell6.current.play();
        // setAudioOn(true);
        // console.log("play clicked")
    }

    // const handleStop = () => {
    //   alarmBell6.current.pause();
    // //   setAudioOn(false);
    // //   setCurrentAlarm([]);
    // //   console.log("pause clicked")
    // }
    
    // convert 12-hour to 24-hour time string cycle sans am/pm
      const checkTime = (timeStr) => {
        let hours = Number(timeStr.match(/^(\d+)/)[1]);
        let minutes = Number(timeStr.match(/:(\d+)/)[1]);
        let AMPM = timeStr.match(/\s(.*)$/)[1];
        if(AMPM === "PM" && hours<12) hours = hours+12;
        if(AMPM === "AM" && hours===12) hours = hours-12;
        if(hours<10) hours = "0" + hours;
        if(minutes<10) minutes = "0" + minutes;
        return hours + ":" + minutes;
    }

    const getList = (day) => {
        // console.log(day);
        let list = [];
        if (day === 3) {
           list = momWkDay.concat(momW);
        //    console.log("W", list);
        } else if (day === 4) {
            list = momWkDay.concat(momR);
            // console.log("R", list);
        } else if (day >= 6) {
            list = wkd;
            // console.log("Wkd", list);
        } else {
            list = momWkDay.concat(momF);
            // console.log("MTF", list);
        }
        // console.log("final", list);
        setAlarmList(list);
    }

    const checkShift = (list, timeStr) => {
        // console.log("state", list);
        // let alarmListCopy = [...list];
        // console.log("copy", alarmListCopy);
        let timeOnlyArr = list.map(item => checkTime(item.time)); //item sent in as string.
        // console.log(timeOnlyArr); //items are 24hr strings with only the time sans am/pm
        let prevArr = timeOnlyArr.filter(item => item < timeStr);
        let start = list.slice(0,prevArr.length);
        let end = list.slice(prevArr.length);
        let startReverse = start.reverse();
        // console.log(startReverse, end);
        setPrevAlarms(startReverse);
        setNextAlarms(end);
        let currentTime = timeOnlyArr.filter(item => item === timeStr);
        // console.log(currentTime);
        setCurrentAlarm(currentTime);
        if (currentTime.length > 0) {
            // setCurrentAlarm(currentTime);
            // setTimeout(playAudio, 3000); //needed me to click in ui in order for alarm to start.
            playAudio();
        } else {
            // handleStop(); //broke ui after alarm
            // setCurrentAlarm([]); //did not break ui after alarm
            return; //did not break ui after alarm
        }
        
        // let currentAlarm = end[0]; //12hr am/pm format
        // console.log(currentAlarm);
        // if (currentAlarm = checkTime())
    }

    //works locally, not when deployed
    // const handleRefresh = () => {
    //     window.location.reload();
    // }

    useEffect(() => {
        document.title = `${props.title} ${props.now}`;
        getList(props.day);
       // const checkAmPm = (list, hhMm) => {
        //     checkShift(list, hhMm);
        // }
       const interval = setInterval(checkShift(alarmList,props.hhMm), 1000);
      return () => {
        clearInterval(interval);
      }

       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.now]);

    return (
        <div>
             {/* <button className="btn" onClick={handleRefresh}>View Alarms</button> */}
            {/* <p>Alarms will appear in one minute. Please do not reload or refresh the site.</p> */}
            {/* <p>Please do not reload or refresh the site.</p> */}          
            <div className="all-alarms">
                <div className="past-alarms">
                    <h2>Past Alarms</h2>
                    {prevAlarms.length > 0 ? prevAlarms.map((item, key) => {
                        return (
                            <ListItem key={key} item={item}/>)
                    }): "No past alarms"}
                </div>
                <div className="next-alarm">
                    {currentAlarm && currentAlarm.length > 0 ?
                    (<div>
                        <h2>Current Alarm</h2>
                        {nextAlarms[0] && <ListItem item={nextAlarms[0]}/> }
                        <div className="bell">
                            <audio ref={alarmBell6} loop={true} src={bell6}>
                            Your browser does not support the audio element.
                            </audio>
                        </div>
                        <p>Alarm will ring for one minute.</p>
                    </div>)
                    :
                    (<div>
                    <h2>Next Alarm</h2>
                    {nextAlarms[0] ?
                        <ListItem item={nextAlarms[0]}/> : "See you tomorrow!"
                    }
                    </div>)
                    }
                </div>
                <div className="upcoming-alarms">
                    <h2>Upcoming Alarms</h2>
                    {nextAlarms.length <= 1 ?  "See you tomorrow!" :
                    nextAlarms.map((item, key) => {
                    if (key > 0) {
                        return (
                        <ListItem key={key} item={item}/>)
                        } else {
                        return null;
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default Mom;