import React, {useEffect} from "react";

const Error = (props) => {
  useEffect(() => {
    document.title = `${props.title} ${props.now}`;
  })
  return (
    <div>
      <div className="shero-section">
        <div className="shero-text">
          <h1>Page not found</h1>
          <p>Please try again</p>
        </div>
      </div>
    </div>
  );
};

export default Error;
